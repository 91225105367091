import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

export const usePost = (endpoint) => {
    const [loading, setLoading] = useState(false);

    const postData = async (payload) => {
        setLoading(true);
        try {
            console.debug("[POST: " + endpoint + "]: Payload", payload);
            let { data } = await axios.post(endpoint, payload);
            console.debug("[POST: " + endpoint + "]: Response", data);
            if (data?.success) {
                setLoading(false);
                return data?.data;
            }
            else {
                throw {response: {data: data}};
            }
        } catch (e) {
            console.error("Post Error: ", e);
            toast.error(e?.response?.data?.message ?? "An unkown error occurred");

            setLoading(false);

            throw e;
        }
    }
    const putData = async (payload, endP) => {
        if (!endP) endP = endpoint;
        setLoading(true);
        try {
            console.debug("[PUT: " + endP + "]: Payload", payload);
            let { data } = await axios.put(endP, payload);
            console.debug("[PUT: " + endP + "]: Response", data);
            if (data?.success) {
                setLoading(false);
                return data?.data;
            }
            else {
                throw data?.message;
            }
        } catch (e) {
            console.error("PUT Error: ", e);
            toast.error(e?.response?.data?.message ?? "An unkown error occurred");
            setLoading(false);

            throw e;
        }
    }

    return { post: postData, put: putData, loading };
}

export const usePut = (endpoint) => {
    const [loading, setLoading] = useState(false);

    const postData = async (payload) => {
        setLoading(true);
        try {
            let { data } = await axios.put(endpoint, payload);
            if (data?.success) {
                setLoading(false);
                return data?.data;
            }
            else throw data?.message;
        } catch (e) {
            toast.error(e?.response?.data?.message ?? "An unkown error occurred");
            console.log("Put Error: ", e);
        }
        setLoading(false);
    }

    return { post: postData, loading };
}

export const useGet = (endpoint, loadInstantly = true, query) => {
    const [loading, setLoading] = useState(loadInstantly);
    const [data, setData] = useState({});

    const loadData = () => {
        setLoading(true);
        axios.get(endpoint, {
            params: {
                ...query
            },
            paramsSerializer: {
                indexes: null
            }
        })
            .then(res => {
                if (res?.data?.success) {
                    setLoading(false);
                    setData(res?.data?.data);
                }
            })
    }
    useEffect(() => {
        if (loadInstantly) loadData();
    }, [endpoint, query])
    return { loading, data, load: loadData };
}

export const useGetPaginated = (endpoint, loadInstantly = true, query) => {
    const [loading, setLoading] = useState(loadInstantly);
    const [data, setData] = useState({});

    const loadData = (pNo = 1, pSize = 10) => {
        setLoading(true);
        axios.get(endpoint + (/\?/.test(endpoint) ? "&" : "?") + `PageNo=${pNo}&PageSize=${pSize}`, {
            params: {
                ...query
            }
        })
            .then(res => {
                if (res?.data?.success) {
                    setData(res?.data?.data);
                    setLoading(false);
                }
            })
    }
    const refresh = () => {
        loadData(data?.pageNo, data?.pageSize);
    }

    useEffect(() => {
        if (loadInstantly) loadData();
    }, [endpoint, query]);

    return { list: data?.list, refresh, pageNo: data?.currentPage, pageSize: data?.pageSize, totalCount: data?.totalCount, totalPages: data?.totalPages, loading, load: loadData }
}