import { Button, Form } from 'antd';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Assets } from '../../assets';
import { MYCInput } from '../../components/input_form.component';
import { usePost } from '../../hooks/useRest.hook';
import { Endpoints } from '../../constants/endpoint.constant';
import { UserContext } from '../../App';
import axios from 'axios';

export const SignInScreen = () => {
    const navigate = useNavigate();
    const loginRq = usePost(Endpoints.LOGIN);
    const { user, setUser } = useContext(UserContext);

    const _onFinish = async values => {
        try {
            var data = await loginRq.post(values);
            axios.defaults.headers["Authorization"] = `Bearer ${data.token}`;
            localStorage.setItem("bearer_token", data.token);
            setUser(data?.user);
            navigate("/");
        
        } catch(e) {
            console.error("[LOGIN] Error",e);
        }
    }
    return <div className='flex flex-col gap-5 justify-center items-center' style={{ height: '100vh', width: '100vw', backgroundImage: `url(${Assets.LoginBG})`, backgroundSize: 'cover' }}>
        <img src={Assets.Logo} className="object-contain w-52 h-32" />
        <div className='text-white bg-primary-orange px-12 py-3'>
            Welcome To Your <span className='text-black'>Portal</span>
        </div>
        <span className='text-white'>
            Safe, trust worthy and money saving driver services.
        </span>
        <div className='w-64 flex flex-row gap-5'>
            <Form onFinish={_onFinish} className="w-full text-white flex flex-col" layout="vertical">
                <MYCInput name="Email" labelColor="white" label="Email" required noMb />
                <MYCInput name="Password" label="Password" labelColor="white" type="password" noMb required />
                <Button loading={loginRq.loading} htmlType="submit" type="primary">Sign In</Button>
            </Form>
        </div>
    </div>
}