import LoginBG from './login-bg.png';
import Logo from './logo.png';
import LogoDark from './logo-dark.png';
import ChatButton from './chat-btn.png';
import TickCircle from './tick-circle.gif';
import Accident from './accident.png';
import Eye from './eye.png';
import Pdf from './pdf.png';
import EyeSquare from './eye-square.png';
import BinSquare from './bin-square.png';
import EditSquare from './edit-square.png';
import Avis from './avis.png';
import CarLeftSide from './car_left_side.png';
import CarRightSide from './car_right_side.png';
import CarFront from './car_front.png';
import CarRear from './car_rear.png';
import Upload from './upload.png'
import CarFrontRight from './front_right.png';
import CarFrontLeft from './front_left.png';
import CarRightRear from './right_rear.png';
import Marker from './marker.png';
import CarLeftRear from './rear_left.png';
import Sign from './sign.png';
import Loading from './loading.gif';
import DownloadSquare from './dl_square.png';

export const Assets = {
    Marker,
    Sign,
    DownloadSquare,
    CarFrontRight,
    CarFrontLeft,
    Upload,
    CarRightRear,
    CarLeftRear,
    CarRear,
    EditSquare,
    CarRightSide,
    CarFront,
    CarLeftSide,
    Avis,
    EyeSquare,
    BinSquare,
    Pdf,
    Loading,
    Eye,
    Accident,
    LogoDark,
    ChatButton,
    LoginBG,
    TickCircle,
    Logo
}