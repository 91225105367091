import { Button, Form } from 'antd';
import React, { useState } from 'react';
import { MYCDate, MYCSearch, MYCSelect } from '../../components/input_form.component';
import { MYCPie } from '../../components/pie_graph.component';
import { useForm } from 'antd/es/form/Form';
import { useGet } from '../../hooks/useRest.hook';
import { Endpoints } from '../../constants/endpoint.constant';

export const ClientGraphScreen = () => {
    const [form] = useForm();
    const [query, setQuery] = useState({});
    const graphRq = useGet(Endpoints.CLIENT_ANALYTICS, true, query)
    return <>
        <span className='self-center text-xl'>Analytics Dashboard</span>
        <Form form={form} onFinish={setQuery} layout='vertical'>
            <div className='flex w-4/5 my-10 flex-row gap-5 items-end justify-start'>
                <MYCSearch name="vtcNo" className={"flex-1"} label={"Search VTC Number"} />

                <MYCDate
                    showTime={false}
                    name="fromDateTime"
                    className={"flex-1"}
                    label="From Date"
                />
                <MYCDate
                    showTime={false}
                    name="toDateTime"
                    className={"flex-1"}
                    label="To Date"
                />
                <Button htmlType={"submit"} loading={graphRq?.loading} className='flex-1' type='primary'>Search</Button>
            </div>
        </Form>
        <div className='grid grid-cols-4 gap-5'>
            <MYCPie className={"h-72"} label="Total Trips Received"
                data={[

                    {
                        name: "Recieved",
                        value: graphRq?.data?.total,
                        primary: true
                    }, {
                        name: "Total",
                        value: 0
                    },
                ]}
            />
            <MYCPie className={"h-72"} label="Total Trips Active"
                data={[

                    {
                        name: "Active",
                        value: graphRq?.data?.totalActiveTransfers,
                        primary: true
                    }, {
                        name: "Total",
                        value: graphRq?.data?.total - graphRq?.data?.totalActiveTransfers
                    },
                ]}
            />
            <MYCPie className={"h-72"} label="Total Trips Completed"
                data={[

                    {
                        name: "Completed",
                        value: graphRq?.data?.totalTransfersCompleted,
                        primary: true
                    }, {
                        name: "Total",
                        value: graphRq?.data?.total - graphRq?.data?.totalTransfersCompleted
                    },
                ]}
            />
            <MYCPie fixedCircle={"R"+ graphRq?.data?.tripsInvoice?.toFixed(2)} className={"h-72"} label="Total Trips Invoice"
                data={[

                     {
                        primary:true,
                        name: "Total",
                        value:1
                    },
                    {
                        name: "All",
                        value:0,
                    },
                ]}
            />
            <MYCPie fixedCircle={graphRq?.data?.totalKM?.toFixed?.(0)} className={"h-72"} label="Total KM"
                data={[

                    {
                        name: "Total KM",
                        value: 1,
                        primary: true
                    },
                    {
                        name: "All",
                        value:0
                    },
                ]}
            />
            <MYCPie className={"h-72"} label="On Time Delivery"
                data={[

                    {
                        name: "On Time",
                        value: graphRq?.data?.onTimeDelivery,
                        primary: true
                    }, {
                        name: "Total",
                        value: graphRq?.data?.total - graphRq?.data?.onTimeDelivery
                    },
                ]}
            />
        </div>
    </>
}