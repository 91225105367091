import { Button, Form } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { MYCInput, MYCPlacesSelect, MYCSelect } from '../../components/input_form.component';
import { useForm } from 'antd/es/form/Form';
import { useGet, usePost } from '../../hooks/useRest.hook';
import { Endpoints, getResourceUrl } from '../../constants/endpoint.constant';
import { useNavigate, useParams } from 'react-router-dom';
import { ClientStatus, Constants } from '../../constants/general.constant';
import { Assets } from '../../assets';
import { SuccessModal } from '../../components/success_modal.component';
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { toast } from 'react-toastify';

export const CreateClientScreen = () => {
    const [form] = useForm();
    const [passForm] = useForm();
    const { id } = useParams();
    const [img, setImg] = useState(null);
    const [suc, setSucModal] = useState(false);
    const inputRef = useRef();
    const readURL = (input) => {
        return new Promise((resolve, reject) => {
            console.log('read', input);
            if (input.target.files && input.target.files[0]) {
                var reader = new FileReader();

                reader.onload = function (e) {
                    resolve(e.target.result);
                };

                reader.readAsDataURL(input.target.files[0]);
            }
        });
    }
    const createRq = usePost(id ? Endpoints.UPDATE_CLIENT(id) : Endpoints.CREATE_CLIENT);
    const imgRq = usePost(Endpoints.UPDATE_USER_PIC)
    const originsRq = useGet(Endpoints.GET_ORIGINS);
    const clientRq = useGet(Endpoints.GET_CLIENT(id), !!id);
    const passRq = usePost(Endpoints.UPDATE_USER_PASSWORD(clientRq?.data?.userId));
    const navigate = useNavigate();
    useEffect(() => {
        if (clientRq?.data?.user) {
            form.setFieldsValue({ ...clientRq?.data, ...clientRq?.data?.user, address:undefined })
        }
    }, [clientRq?.data])
    const {
        placesService,
        placePredictions,
        getPlacePredictions,
        isPlacePredictionsLoading,
    } = usePlacesService({
        apiKey: Constants.GOOGLE_API_KEY,
    });
    const getPlaceDetails = placeId => {
        return new Promise(async (resolve, reject) => {
            placesService.getDetails({
                placeId: placeId
            }, (data) => {
                if (data) {
                    return resolve({
                        name: data?.formatted_address,
                        lat: data?.geometry?.location?.lat(),
                        lng: data?.geometry?.location?.lng(),
                    })
                }
            })
        })
    }

    const _onFinish = async values => {
        if (inputRef?.current?.files?.length > 0) {
            var size = inputRef?.current?.files[0].size;
            const kbytes = Math.round((size / 1024));
            if (kbytes > 3072) {
                toast.error("Image is greater than 3MB, please select a image smaller than 3MB! ");
                return;
            }
        }
        values.username = values.name;
        values.contactPerson = values.name;
        if (values.address) {
            let location = await getPlaceDetails(values.address);
            values.address = location.name;
            values.lat = location.lat;
            values.long = location.lng;
        } else {
            values.address = clientRq?.data?.address;
            values.lat = clientRq?.data?.lat;
            values.long = clientRq?.data?.long;
        }
        try {
            let data = await createRq.post(values);
            if (inputRef?.current?.files?.length > 0) {
                let formData = new FormData();
                formData.append("id", data?.userId || data?.id);
                formData.append("image", inputRef?.current?.files[0]);
                await imgRq?.put(formData);
            }
            navigate("/clients", {
                replace: true
            });
        } catch (e) {
            console.error("[CREATE CLIENT] error", e);
        }
    }

    const _onUpdatePassword = async values => {
        try {
            let data = await passRq?.post(values);
            setSucModal("Password Updated Succesfully");

        } catch {

        }
    }
    return <>
        <SuccessModal message={suc} open={suc} onClose={setSucModal?.bind(this, false)} />

        <span className='self-center text-xl my-5'>{!id ? "Create" : "Update"} Client Profile</span>
        <input type="file" ref={inputRef} onChange={(e) => readURL(e).then(e => setImg(e))} hidden />
        <Form onFinish={_onFinish} form={form} layout='vertical'>
            <div className='grid grid-cols-2 gap-5 px-24'>
                <MYCInput name="name" label={"Entity Name"} required />
                <MYCSelect options={
                    originsRq?.data?.map?.(M => ({ value: M.id, label: M.name }))
                } name="originId" label={"Region"} required />
                <MYCInput name="surname" label={"Client Full Name"} required />
                <MYCInput name="number" label={"Number"} required />
                <MYCInput name="email" label={"Email"} required />
                {!id && <MYCInput name="password" label={"Client Password"} type="password" required />}
                <MYCPlacesSelect hint={clientRq?.data?.address} name="address" label={"Address"} required={!id} />
                <MYCSelect name="status" className="flex-1" label="Status" options={
                    ClientStatus.map(M => ({ label: M.label, value: M.id }))
                }
                />
                <MYCInput name="pricePerKM" label={"Price / KM"} type='number' required />
                <div className="flex gap-5 items-center flex-col">
                    <div className="rounded-full border flex flex-col items-center justify-center border-solid border-b overflow-hidden w-32 h-32 ">
                        <img src={img || (clientRq?.data?.user?.picutre ? getResourceUrl(clientRq?.data?.user?.picutre) : false) || Assets.Upload} className={`object-contain ${img || clientRq?.data?.user?.picutre ? "w-full h-full" : "w-10 h-10"}`} />
                    </div>
                    <Button type="primary" onClick={() => inputRef?.current?.click()}>Upload Image</Button>
                </div>

                <div className='flex flex-row justify-end items-end'>
                    <Button htmlType='submit' loading={createRq?.loading || imgRq?.loading} type='primary' className=' w-1/2'>{!id ? "Create" : "Update"} Client</Button>
                </div>
            </div>
        </Form>
        {clientRq?.data?.userId && <>
            <span className='self-center text-center text-xl my-5'>Update Password</span>
            <Form onFinish={_onUpdatePassword} form={passForm} layout="vertical">

                <div className='grid grid-cols-2 py-10 gap-5 px-24'>

                    <MYCInput type="password" name="UpdatedPassword" label="Enter Password" />
                    <MYCInput type="password" name="ConfirmPassword" label="Confirm Password" />
                    <div />
                    <Button loading={passRq?.loading} htmlType='submit' type='primary' className=' w-1/2'>{!id ? "Create" : "Update"} Password</Button>
                </div>
            </Form>
        </>}
    </>
}