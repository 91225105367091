import { AccidentScreen } from "../screens/common/accidents.screen";
import { AccidentDetailScreen } from "../screens/common/accident_detail.screen";
import { ContactScreen } from "../screens/client/contact.screen";
import { ClientGraphScreen } from "../screens/client/graph.screen";
import { MessagesScreen } from "../screens/client/messages.screen";
import { NewOrderScreen } from "../screens/common/new_order.screen";
import { ServicesViewScreen } from "../screens/client/services.screen";
import { TrackOrderScreen } from "../screens/common/track.screen";
import { TripOrderHistoryScreen } from "../screens/common/trip_order_history";
import { ActiveTripsScreen } from "../screens/admin/active_trip_orders.screen";

export const ClientNavigator = () => [
    {
        name: "New Order",
        link: "new-trip-order",
        page: <NewOrderScreen/>
    },
    {
        name: "New Order",
        link: "new-order",
        noSidebar:true,
        page: <NewOrderScreen/>
    },
    {
        name: "Order History",
        link: "order-history",
        page: <ActiveTripsScreen key="history" history/>
    },
    {
        name: "Active Trip Orders",
        link: "trip-orders",
        page: <ActiveTripsScreen key="active" />
    },
    {
        name: "Navigation",
        link: "track",
        page: <TrackOrderScreen/>
    },
    {
        name: "Messages",
        link: '',
        page: <MessagesScreen/>,
        noSidebar: true,
    },
    {
        name: "Messages",
        link: 'sign-in',
        page: <MessagesScreen/>,
        noSidebar: true,
    },
    {
        name: "Messages",
        link: 'messages',
        page: <MessagesScreen/>,
        noSidebar: true,
    },
    {
        name: "Accident Reports",
        link: "accident-reports",
        page: <AccidentScreen/>
    },
    {
        name: "Accident Details",
        link: "accident-details/:id",
        page: <AccidentDetailScreen/>,
        noSidebar: true
    },
    {
        name: "Analytics",
        link: "analytics",
        page: <ClientGraphScreen/>
    },
    {
        name: "Services",
        link: "services",
        page: <ServicesViewScreen/>
    },
    {
        name: "Contact Us",
        link: "contact-us",
        page: <ContactScreen/>
    }
]