import { Button, Checkbox, Form, Modal, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { Assets } from '../assets';
import { MYCInput, MYCRemoteSearchSelect, MYCSearch, MYCSelect, MYCTextArea } from './input_form.component';
import { Constants, TRIP_STATUSES, TripAssignStatuses, TripStatus, TripType, isAdmin } from '../constants/general.constant';
import { Endpoints, getResourceUrl } from '../constants/endpoint.constant';
import { useForm } from 'antd/es/form/Form';
import { useGet, useGetPaginated, usePost } from '../hooks/useRest.hook';
import { SuccessModal } from './success_modal.component';
import { MYCTable } from './table.component';
import { BlobProvider, PDFDownloadLink, pdf, usePDF } from '@react-pdf/renderer';
import { CancelDoc, CompareDocument, CompleteDoc, InspectionDoc, TripOrderDoc } from '../documents/triporder.doc';
import { useFetcher } from 'react-router-dom';
import { saveAs } from 'file-saver';

const FieldView = ({ label, value, height, children }) => {
    return <div className="flex flex-col gap-3 ">
        <span>{label}</span>
        <div style={{ height }} className="border border-solid self-stretch text-center px-5 py-3">
            {value || children}
        </div>
    </div>
}
const ChoiceView = ({ label, yes, yesLabel = "Yes", noLabel = "No" }) => {
    return <div className="flex flex-col gap-3 items-center">
        <span>{label}</span>
        <div className="border flex flex-row justify-between gap-10  border-solid px-10 py-5 ">
            <div className="flex items-center flex-row gap-2">
                <span>{yesLabel}</span>
                <div className={`${yes ? "bg-primary-orange" : "border border-solid"}  w-5 h-5 `}></div>
            </div>
            <div className="flex items-center flex-row gap-2">
                <span>{noLabel}</span>
                <div className={`${!yes ? "bg-primary-orange" : "border border-solid"}  w-5 h-5 `}></div>
            </div>
        </div>
    </div>
}

const VehicleReviewBlock = ({ setImgModal, label, windscreen, image, dent, scratch, comment }) => {
    return <div className="flex flex-col gap-5">
        <div className="bg-primary-orange py-5 text-white text-center">
            {label}
        </div>
        <div className="grid grid-cols-2 gap-5">
            {image?.map?.(M => <img onClick={setImgModal?.bind(this, M?.pic)} src={getResourceUrl(M?.pic)} className="object-contain w-full h-32" />)}
        </div>
        {!windscreen && <><ChoiceView label="Dent" yes={dent} />
            <ChoiceView label="Scratch" yes={scratch} />
        </>}
        <FieldView label="Comments" value={comment} />
    </div>
}


const InspectComponent = ({ inspect, setImgModal }) => {
    return <div className="gap-5 flex flex-col px-3">
        <FieldView label="Ref Number" value={inspect?.refNumber} />
        <FieldView label="Registration Number" value={inspect?.regsitrationNumber} />
        <FieldView label="Make" value={inspect?.make} />
        <FieldView label="Model" value={inspect?.model} />
        <FieldView label="Colour" value={inspect?.colour} />
        <FieldView label="Date Out" value={new Date(inspect?.dateOut).toLocaleDateString()} />
        <FieldView label="KMs Out" value={inspect?.kmsOut} />
        <FieldView label="Odometer">
            <img onClick={setImgModal.bind(this, inspect?.odometer)} src={getResourceUrl(inspect?.odometer)} className="h-64 self-center object-contain" />
        </FieldView>
        <FieldView label="Time Out" value={new Date(inspect?.timeOut).toLocaleTimeString()} />
        <FieldView label="From (source)" value={inspect?.source} />
        <FieldView label="To (destination)" value={inspect?.destination} />
        <ChoiceView label="Fuel" yes={inspect?.fuel} />
        <FieldView label="Type" value={inspect?.type} />
        <FieldView label="Lic Expiry Date" value={new Date(inspect?.licExpiry).toLocaleString()} />
        <FieldView label="Driver" value={inspect?.driver?.user?.name} />
        <span className="self-center text-xl">Vehicle Inspection</span>
        <VehicleReviewBlock setImgModal={setImgModal} label="Left Side" dent={inspect?.leftDent} scratch={inspect?.leftScratch} comment={inspect?.leftCommnet} image={inspect?.leftPics} />
        <VehicleReviewBlock setImgModal={setImgModal} label="Right Side" dent={inspect?.rightDent} scratch={inspect?.rightScratch} comment={inspect?.rightComment} image={inspect?.rightPics} />
        <VehicleReviewBlock setImgModal={setImgModal} label="Car Front" dent={inspect?.frontDent} scratch={inspect?.frontScratch} comment={inspect?.frontComment} image={inspect?.frontPics} />
        <VehicleReviewBlock label="Car Rear" setImgModal={setImgModal} dent={inspect?.backDent} scratch={inspect?.backScratch} comment={inspect?.backComment} image={inspect?.backPics} />
        <VehicleReviewBlock label="Windscreen" windscreen dent={false} setImgModal={setImgModal} scratch={false} comment={inspect?.windScreenComment} image={inspect?.windScreenPics} />
        <span className="self-center text-xl">Tyre Inspection</span>

        <TyreReviewBlock setImgModal={setImgModal} label="Left Front Tyre" tread={inspect?.leftFrontTireTread} pressure={inspect?.leftFrontTirePressure} image={inspect?.leftFrontTirePics} make={inspect?.leftFrontTireMake} />
        <TyreReviewBlock setImgModal={setImgModal} label="Left Back Tyre" tread={inspect?.leftBackTireTread} pressure={inspect?.leftBackTirePressure} image={inspect?.leftBackTirePics} make={inspect?.leftBackTireMake} />
        <TyreReviewBlock setImgModal={setImgModal} label="Right Back Tyre" tread={inspect?.rightFrontTireTread} pressure={inspect?.rightFrontTirePressure} image={inspect?.rightFrontTirePics} make={inspect?.rightFrontTireMake} />
        <TyreReviewBlock setImgModal={setImgModal} label="Right Front Tyre" tread={inspect?.rightBackTireTread} pressure={inspect?.rightBackTirePressure} image={inspect?.rightBackTirePics} make={inspect?.rightBackTireMake} />
        <span className="self-center text-xl">Sign Off</span>

        <ChoiceView label="E Tag" yes={inspect?.etag} />
        <ChoiceView label="Tools & Jack" yes={inspect?.toolsAndJack} />
        <ChoiceView label="Spare Wheel" yes={inspect?.spareWheel} />
        <ChoiceView label="Removal Hubcaps" yes={inspect?.removableHubcaps} />
        <ChoiceView label="Triangle" yes={inspect?.triangle} />
        <ChoiceView label="Parcel Tray" yes={inspect?.parcelTray} />
        <ChoiceView label="Trailer" yes={inspect?.trailer} />
        <ChoiceView label="Tow Bar" yes={inspect?.towBar} />
        <FieldView label="Date" value={new Date(inspect?.createdAt).toLocaleString()} />
        <FieldView label="Driver Signature">
            <img src={getResourceUrl(inspect?.driverSignature)} className="w-64 h-32 self-center object-contain" />
        </FieldView>
        <FieldView label="Company name">{inspect?.companyName}</FieldView>
        <FieldView label="Region">{inspect?.region}</FieldView>
        <FieldView label="Client dispatcher">{inspect?.personName}</FieldView>
        <FieldView label="Mobile number">{inspect?.number}</FieldView>
        <FieldView label="Client Signature">
            <img src={getResourceUrl(inspect?.signature)} className="w-64 h-32  self-center object-contain" />
        </FieldView>
    </div>
}


const CompareInspectModal = ({ inspect, postInspect, open, onCancel, title, tripOrderId }) => {
    const [imgModal, setImgModal] = useState(null);
    return <> <Modal width={900} footer={null} open={open} title={title} onCancel={onCancel}>
        <div className="grid grid-cols-2  gap-5" style={{gridTemplateColumns:'repeat(2, minmax(0, 1fr))'}}>
            <FieldView label="Ref Number" value={inspect?.refNumber} />
            <FieldView label="Ref Number" value={postInspect?.refNumber} />
            <FieldView label="Registration Number" value={inspect?.regsitrationNumber} />
            <FieldView label="Registration Number" value={postInspect?.regsitrationNumber} />
            <FieldView label="Make" value={inspect?.make} />
            <FieldView label="Make" value={postInspect?.make} />
            <FieldView label="Model" value={inspect?.model} />
            <FieldView label="Model" value={postInspect?.model} />
            <FieldView label="Colour" value={inspect?.colour} />
            <FieldView label="Colour" value={postInspect?.colour} />
            <FieldView label="Date Out" value={new Date(inspect?.dateOut).toLocaleDateString()} />
            <FieldView label="Date Out" value={new Date(postInspect?.dateOut).toLocaleDateString()} />
            <FieldView label="KMs Out" value={inspect?.kmsOut} />
            <FieldView label="KMs Out" value={postInspect?.kmsOut} />
            <FieldView label="Odometer">
                <img onClick={setImgModal.bind(this, inspect?.odometer)} src={getResourceUrl(inspect?.odometer)} className="h-64 self-center object-contain" />
            </FieldView>
            <FieldView label="Odometer">
                <img onClick={setImgModal.bind(this, postInspect?.odometer)} src={getResourceUrl(postInspect?.odometer)} className="h-64 self-center object-contain" />
            </FieldView>
            <FieldView label="Time Out" value={new Date(inspect?.timeOut).toLocaleTimeString()} />
            <FieldView label="Time Out" value={new Date(postInspect?.timeOut).toLocaleTimeString()} />

            <FieldView label="From (source)" value={inspect?.source} />
            <FieldView label="From (source)" value={postInspect?.source} />
            <FieldView label="To (destination)" value={inspect?.destination} />
            <FieldView label="To (destination)" value={postInspect?.destination} />
            <ChoiceView label="Fuel" yes={inspect?.fuel} />
            <ChoiceView label="Fuel" yes={postInspect?.fuel} />
            <FieldView label="Type" value={inspect?.type} />
            <FieldView label="Type" value={postInspect?.type} />
            <FieldView label="Lic Expiry Date" value={new Date(inspect?.licExpiry).toLocaleString()} />
            <FieldView label="Lic Expiry Date" value={new Date(postInspect?.licExpiry).toLocaleString()} />
            <FieldView label="Driver" value={inspect?.driver?.user?.name} />
            <FieldView label="Driver" value={postInspect?.driver?.user?.name} />
            <span className="self-center text-xl col-span-2 text-center">Vehicle Inspection</span>
            <VehicleReviewBlock setImgModal={setImgModal} label="Left Side" dent={inspect?.leftDent} scratch={inspect?.leftScratch} comment={inspect?.leftCommnet} image={inspect?.leftPics} />
            <VehicleReviewBlock setImgModal={setImgModal} label="Left Side" dent={postInspect?.leftDent} scratch={postInspect?.leftScratch} comment={postInspect?.leftCommnet} image={postInspect?.leftPics} />

            <VehicleReviewBlock setImgModal={setImgModal} label="Right Side" dent={inspect?.rightDent} scratch={inspect?.rightScratch} comment={inspect?.rightComment} image={inspect?.rightPics} />
            <VehicleReviewBlock setImgModal={setImgModal} label="Right Side" dent={postInspect?.rightDent} scratch={postInspect?.rightScratch} comment={postInspect?.rightComment} image={postInspect?.rightPics} />

            <VehicleReviewBlock setImgModal={setImgModal} label="Car Front" dent={inspect?.frontDent} scratch={inspect?.frontScratch} comment={inspect?.frontComment} image={inspect?.frontPics} />
            <VehicleReviewBlock setImgModal={setImgModal} label="Car Front" dent={postInspect?.frontDent} scratch={postInspect?.frontScratch} comment={postInspect?.frontComment} image={postInspect?.frontPics} />

            <VehicleReviewBlock label="Car Rear" setImgModal={setImgModal} dent={inspect?.backDent} scratch={inspect?.backScratch} comment={inspect?.backComment} image={inspect?.backPics} />
            <VehicleReviewBlock label="Car Rear" setImgModal={setImgModal} dent={postInspect?.backDent} scratch={postInspect?.backScratch} comment={postInspect?.backComment} image={postInspect?.backPics} />

            <VehicleReviewBlock label="Windscreen" windscreen dent={false} setImgModal={setImgModal} scratch={false} comment={inspect?.windScreenComment} image={inspect?.windScreenPics} />
            <VehicleReviewBlock label="Windscreen" windscreen dent={false} setImgModal={setImgModal} scratch={false} comment={postInspect?.windScreenComment} image={postInspect?.windScreenPics} />

            <span className="self-center text-xl col-span-2 text-center">Tyre Inspection</span>

            <TyreReviewBlock setImgModal={setImgModal} label="Left Front Tyre" tread={inspect?.leftFrontTireTread} pressure={inspect?.leftFrontTirePressure} image={inspect?.leftFrontTirePics} make={inspect?.leftFrontTireMake} />
            <TyreReviewBlock setImgModal={setImgModal} label="Left Front Tyre" tread={postInspect?.leftFrontTireTread} pressure={postInspect?.leftFrontTirePressure} image={postInspect?.leftFrontTirePics} make={postInspect?.leftFrontTireMake} />

            <TyreReviewBlock setImgModal={setImgModal} label="Left Back Tyre" tread={inspect?.leftBackTireTread} pressure={inspect?.leftBackTirePressure} image={inspect?.leftBackTirePics} make={inspect?.leftBackTireMake} />
            <TyreReviewBlock setImgModal={setImgModal} label="Left Back Tyre" tread={postInspect?.leftBackTireTread} pressure={postInspect?.leftBackTirePressure} image={postInspect?.leftBackTirePics} make={postInspect?.leftBackTireMake} />
            
            <TyreReviewBlock setImgModal={setImgModal} label="Right Back Tyre" tread={inspect?.rightFrontTireTread} pressure={inspect?.rightFrontTirePressure} image={inspect?.rightFrontTirePics} make={inspect?.rightFrontTireMake} />
            <TyreReviewBlock setImgModal={setImgModal} label="Right Back Tyre" tread={postInspect?.rightFrontTireTread} pressure={postInspect?.rightFrontTirePressure} image={postInspect?.rightFrontTirePics} make={postInspect?.rightFrontTireMake} />

            <TyreReviewBlock setImgModal={setImgModal} label="Right Front Tyre" tread={inspect?.rightBackTireTread} pressure={inspect?.rightBackTirePressure} image={inspect?.rightBackTirePics} make={inspect?.rightBackTireMake} />
            <TyreReviewBlock setImgModal={setImgModal} label="Right Front Tyre" tread={postInspect?.rightBackTireTread} pressure={postInspect?.rightBackTirePressure} image={postInspect?.rightBackTirePics} make={postInspect?.rightBackTireMake} />
            <span className="self-center text-xl text-center col-span-2">Sign Off</span>

            <ChoiceView label="E Tag" yes={inspect?.etag} />
            <ChoiceView label="E Tag" yes={postInspect?.etag} />

            <ChoiceView label="Tools & Jack" yes={inspect?.toolsAndJack} />
            <ChoiceView label="Tools & Jack" yes={postInspect?.toolsAndJack} />

            <ChoiceView label="Spare Wheel" yes={inspect?.spareWheel} />
            <ChoiceView label="Spare Wheel" yes={postInspect?.spareWheel} />

            <ChoiceView label="Removal Hubcaps" yes={inspect?.removableHubcaps} />
            <ChoiceView label="Removal Hubcaps" yes={postInspect?.removableHubcaps} />

            <ChoiceView label="Triangle" yes={inspect?.triangle} />
            <ChoiceView label="Triangle" yes={postInspect?.triangle} />

            <ChoiceView label="Parcel Tray" yes={inspect?.parcelTray} />
            <ChoiceView label="Parcel Tray" yes={postInspect?.parcelTray} />

            <ChoiceView label="Trailer" yes={inspect?.trailer} />
            <ChoiceView label="Trailer" yes={postInspect?.trailer} />

            <ChoiceView label="Tow Bar" yes={inspect?.towBar} />
            <ChoiceView label="Tow Bar" yes={postInspect?.towBar} />

            <FieldView label="Date" value={new Date(inspect?.createdAt).toLocaleString()} />
            <FieldView label="Date" value={new Date(postInspect?.createdAt).toLocaleString()} />

            <FieldView label="Driver Signature">

                <img src={getResourceUrl(inspect?.driverSignature)} className="w-64 h-32 self-center object-contain" />
            </FieldView>
            <FieldView label="Driver Signature">

                <img src={getResourceUrl(postInspect?.driverSignature)} className="w-64 h-32 self-center object-contain" />
            </FieldView>

            <FieldView label="Company name">{inspect?.companyName}</FieldView>
            <FieldView label="Company name">{postInspect?.companyName}</FieldView>

            <FieldView label="Region">{inspect?.region}</FieldView>
            <FieldView label="Region">{postInspect?.region}</FieldView>

            <FieldView label="Client dispatcher">{inspect?.personName}</FieldView>
            <FieldView label="Client dispatcher">{postInspect?.personName}</FieldView>

            <FieldView label="Mobile number">{inspect?.number}</FieldView>
            <FieldView label="Mobile number">{postInspect?.number}</FieldView>

            <FieldView label="Client Signature">
                <img src={getResourceUrl(inspect?.signature)} className="w-64 h-32  self-center object-contain" />
            </FieldView>
            <FieldView label="Client Signature">
                <img src={getResourceUrl(postInspect?.signature)} className="w-64 h-32  self-center object-contain" />
            </FieldView>
        </div>
        <div className="w-full mt-5 flex flex-row justify-end">
            <Button type="primary" onClick={onCancel}>Close</Button>
        </div>
    </Modal>
        <Modal width={700} footer={null} className="flex flex-col items-center" open={imgModal} title="Image View" onCancel={setImgModal.bind(this, false)}>
            <img src={getResourceUrl(imgModal)} style={{ width: 500, height: 500 }} className="object-contain" />
        </Modal>
    </>
}

const InspectionModal = ({ inspect, open, onCancel, title, tripOrderId, postInspect }) => {
    const [imgModal, setImgModal] = useState(null);
    return <> <Modal footer={null} open={open} title={title} onCancel={onCancel}>
        <InspectComponent inspect={inspect} setImgModal={setImgModal} />
        <div className="w-full mt-5 flex flex-row justify-end">
            <Button type="primary" onClick={onCancel}>Close</Button>
        </div>
    </Modal>
        <Modal width={700} footer={null} className="flex flex-col items-center" open={imgModal} title="Image View" onCancel={setImgModal.bind(this, false)}>

            <img src={getResourceUrl(imgModal)} style={{ width: 500, height: 500 }} className="object-contain" />
        </Modal>
    </>
}

const TyreReviewBlock = ({ setImgModal, label, image, tread, pressure, make }) => {
    return <div className="flex flex-col gap-5">
        <div className="bg-primary-orange py-5 text-white text-center">
            {label}
        </div>
        <div className="grid grid-cols-2 gap-5">
            {image?.map?.(M => <img onClick={setImgModal?.bind(this, M?.pic)} src={getResourceUrl(M?.pic)} className="object-contain w-full h-32" />)}
        </div>
        <ChoiceView label="Tread" yesLabel="Good" noLabel="Bad" yes={tread} />
        <ChoiceView label="Pressure" yes={pressure} />
        <FieldView label="Make" value={make} />
    </div>
}

const generateCompareDocument = async ({ data, fileName }) => {
    const blob = await pdf((
        <CompareDocument
            {...data}
        />
    )).toBlob();
    saveAs(blob, fileName);
}

const generatePdfDocument = async ({ data, fileName }) => {
    const blob = await pdf((
        <CompleteDoc
            {...data}
        />
    )).toBlob();
    saveAs(blob, fileName);
}
const InspectionButton = ({ rq, trip, post = false }) => {
    const [inspect, setInspect] = useState(false);


    return rq?.data?.id ? <div className="flex flex-col gap-5"> <span className="font-bold">
        {post ? "Post" : "Pre"}-Inspection
    </span>
        <InspectionModal tripOrderId={trip?.id} inspect={rq?.data} open={inspect} title={`${post ? "Post" : "Pre"} Inspection Checklist`} onCancel={setInspect.bind(this, false)} />

        <div className="flex flex-row gap-2">
            <img src={Assets.EyeSquare} onClick={setInspect.bind(this, true)} className="cursor-pointer w-6 h-6" />
            <PDFDownloadLink fileName={`MYC-${trip?.id}-${post ? "post" : "pre"}-inspection.pdf`} document={<InspectionDoc inspection={rq?.data} trip={trip} post={post} />} >
                {({ loading, url }) => {
                    return <div className="flex flex-row gap-2">
                        {loading ? <img src={Assets.Loading} className="w-6 h-6" /> : <img src={Assets.DownloadSquare} className="w-6 h-6" />
                        }</div>
                }}
            </PDFDownloadLink>
        </div>
    </div> : <div />
}

const CompareButton = ({ rq, postRq, trip, post = false }) => {
    const [inspect, setInspect] = useState(false);


    return (rq?.data?.id && postRq?.data?.id) ? <div className="flex flex-col gap-5"> <span className="font-bold">
        Compare Post and Pre Inspection
    </span>
        <CompareInspectModal tripOrderId={trip?.id} inspect={rq?.data} postInspect={postRq?.data} open={inspect} title={`Compare Inspection Checklist`} onCancel={setInspect.bind(this, false)} />

        <div className="flex flex-row gap-2">
            <img src={Assets.EyeSquare} onClick={setInspect.bind(this, true)} className="cursor-pointer w-6 h-6" />
            <img src={Assets.DownloadSquare} onClick={() => generateCompareDocument({ data: { trip, inspect: rq?.data, postInspect: postRq?.data }, fileName: `MYC-${trip?.id}-` + "comparision.pdf" })} className="cursor-pointer w-6 h-6" />
        </div>
    </div> : <div />
}

export const TripOrderCard = ({ trip, refresh, origins, driver }) => {
    const [expanded, setExpanded] = useState(false);
    const [cancelModal, setCancelModal] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [rcvsModal, setRcvsModal] = useState(false);

    const driverReRq = usePost(Endpoints.ASSIGN_DRIVER(trip?.id, null))
    const driverMsgRq = usePost(Endpoints.DRIVER_MSG);
    const cancelRq = usePost(Endpoints.CANCEL_TRIP_ORDER(trip?.id));
    const tripAssignHistories = useGet(Endpoints.TRIP_ASSIGN_HISTORIES, false, {
        tripOrderHistoryId: trip?.id
    });
    const preRq = useGet((Endpoints.GET_PRE_INS(trip?.id)), false);
    const postRq = useGet((Endpoints.GET_POST_INS(trip?.id)), false);


    const tripOrderMessages = useGetPaginated(Endpoints.GET_TRIPORDER_MESSAGES(trip?.id), false);

    const [driverReassignForm] = useForm();
    const [driverMsgForm] = useForm();
    const [cancelForm] = useForm();
    const originId = Form.useWatch('originId', driverMsgForm);
    const allDrivers = Form.useWatch('allDrivers', driverMsgForm);

    useEffect(() => {
        if (expanded) {
            tripAssignHistories?.load?.();
            if (!preRq?.data?.id && trip?.status !== TripStatus.Cancelled) {
                preRq?.load?.();
                postRq?.load?.();
            }
            if (isAdmin) {
                tripOrderMessages?.load?.();
            }

        }
    }, [expanded])



    const _driverMsg = async values => {
        values.tripOrderId = trip?.id;
        values.driverIds = values.driverIds || [];
        try {
            let data = await driverMsgRq.post(values);
            setSuccessModal("Trip order sent to driver(s) successfully");
            refresh();
            tripOrderMessages?.load?.();
        } catch (e) {

        }
    }

    const _driverReassign = async values => {
        try {
            let data = await driverReRq?.put({}, Endpoints.ASSIGN_DRIVER(trip?.id, values?.driverId));
            setSuccessModal("Driver reassigned successfully");
            tripAssignHistories?.load?.();
            refresh();
        } catch (e) {

        }
    }
    const _cancelTripOrder = async values => {
        try {
            let data = await cancelRq?.post(values);
            setCancelModal(false);
            refresh();
        }
        catch (e) {

        }
    }
    const postInspectRq = useGet((Endpoints.GET_POST_INS(trip?.id)), false);

    const reassignedStatus = tripAssignHistories.data?.find?.(F => F.status === TripAssignStatuses.REASSIGNED_TO);
    const acceptedStatus = tripAssignHistories.data?.find?.(F => F.status === TripAssignStatuses.ACCEPTED_BY);

    // const CompletePdf = usePDF({ document: CompleteDoc({ inspection: preInspectRq?.data, postInspection: postInspectRq?.data, trip: trip, acceptedStatus: acceptedStatus }) })





    return <div className="border px-16 py-5 border-black border-solid flex flex-col">
        <SuccessModal open={successModal} onClose={setSuccessModal?.bind(this, false)} message={successModal} />
        <div id={`TRIP-CARD-${trip?.id}`} className={`border-b border-transparent ${expanded ? "border-b-black border-solid" : ""} gap-5  flex flex-row items-center`}>


            <div className="grid   grid-cols-7 py-5 gap-5 flex-1">
                <span className="font-bold">Client name</span>
                <span className="font-bold">Ref Number</span>
                <span className="font-bold">Date</span>
                <span className="font-bold">Trip Status</span>
                <span className="font-bold">From</span>
                <span className="font-bold">To</span>
                <span className="font-bold">MYC Ref No</span>

                <span>{trip?.client?.user?.name}</span>
                <span>{trip?.clientReferenceNumber}</span>
                <span>{new Date(trip?.startDateTime).toLocaleDateString()}</span>
                <span className="text-green-600">{TRIP_STATUSES[trip?.status] + (trip?.status === TripStatus.Pending ? (" driver " + driver?.user?.name ?? " drivers") : "") + ((trip?.status === TripStatus.DriverAccepted || trip?.status === TripStatus.TripStarted) ? ` driver ${trip?.driver?.user?.name ?? ''}` : '')}</span>
                <span>{trip?.pickUp}</span>
                <span>{trip?.dropOff}</span>
                <span>#{trip?.id}</span>

                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <Button onClick={setExpanded.bind(this, !expanded)} type="primary">View {expanded ? "Less" : "More"}</Button>
            </div>
        </div>
        {expanded && <>
            <div className="flex border-b border-transparent border-b-black border-solid flex-col gap-5 py-5">
                <span className="self-start flex-bold">Trip Order Overview</span>
                <div className="grid grid-cols-7 gap-5">
                    <span className="font-bold">Vehicle Reg No</span>
                    <span className="font-bold">Vehicle Type</span>
                    <span className="font-bold">Amount excl VAT</span>
                    {process.env.REACT_APP_MODE === Constants.ADMIN ? <span className="font-bold">Rate/KM</span> : <div />}

                    <span className="font-bold">Start Date</span>
                    <span className={trip?.type === TripType.Return ? "text-primary-orange" : ""}>{trip?.type === TripType.Return ? "Shuttle/Pilot" : "Start Time"}</span>
                    <span className="font-bold">Total KM</span>
                    <span>{trip?.vehicleRegNumber}</span>
                    <span>{trip?.vehicleType}</span>
                    <span className="text-green-600">R{trip?.price?.toFixed(2)}</span>
                    {process.env.REACT_APP_MODE === Constants.ADMIN ? <span>R{trip?.client?.pricePerKM}</span> : <div />}

                    <span>{new Date(trip?.startDateTime).toLocaleDateString()}</span>
                    <span className={trip?.type === TripType.Return ? "text-primary-orange" : ""}>{trip?.type === TripType.Return ? "" : new Date(trip?.startDateTime).toLocaleTimeString()}</span>
                    <span>{trip?.totalKM?.toFixed?.(2)}KM</span>
                </div>
            </div>
            {isAdmin && !trip?.driver?.id && <div className="flex py-5 border-b border-transparent border-b-black border-solid flex-col gap-5 ">
                <span className="self-start font-bold">Driver Trip Order Message Onboarding</span>
                <Form onFinish={_driverMsg} form={driverMsgForm}>
                    <div className="grid grid-cols-8 gap-2">
                        <span className="font-bold col-span-4">Assign Trip Order Message To Driver(s)</span>
                        <span className="font-bold">All Driver in Region</span>
                        <span className="font-bold text-center">Message Status</span>
                        <span className="font-bold">Message Recipients</span>
                        <div className="flex flex-row gap-3 col-span-4">
                            <MYCSelect label="Region" name="originId" className="flex-1" required
                                options={origins?.map?.(M => ({ label: M?.name, value: M?.id }))}
                            />
                            {!allDrivers && <MYCRemoteSearchSelect onlyActive required={!allDrivers} disabled={allDrivers} name="driverIds" multiple labelDef={d => d?.user?.name + " (" + d?.user?.email + ")"} label="Driver" url={Endpoints.ALL_DRIVERS + "?originId=" + (originId || '')} searchAttr={"driverName"} className="flex-1" />}
                        </div>
                        <Form.Item className="mx-auto" name="allDrivers" valuePropName="checked" label="">
                            <Checkbox></Checkbox>
                        </Form.Item>
                        <span className="text-center text-green-500">Sent</span>
                        <img onClick={setRcvsModal.bind(this, true)} src={Assets.EyeSquare} className="w-7 h-7 mx-auto object-contain cursor-pointer" />
                        <Button loading={driverMsgRq?.loading} htmlType={'submit'} type="primary" className="col-span-4">Send Trip Order To Driver(s)</Button>
                    </div>
                </Form>
            </div>}
            {trip?.driver &&
                <div className="flex py-5 border-b border-transparent border-b-black border-solid flex-col gap-5 ">
                    <span className="self-start font-bold">Driver Allocated</span>
                    <div className="grid grid-cols-4 gap-5">
                        <span className="font-bold">Driver Name</span>
                        <span className="font-bold">Time of Acceptance</span>
                        <span className="font-bold">Date of Acceptance</span>
                        <span className="font-bold">Status</span>
                        <span className="text-myc-blue">{trip?.driver?.user?.name}</span>
                        <span className=""> {new Date((acceptedStatus ?? reassignedStatus)?.createdAt).toLocaleTimeString()}</span>
                        <span className=""> {new Date((acceptedStatus ?? reassignedStatus)?.createdAt).toLocaleDateString()}</span>
                        <span className="text-green-500">Accepted</span>
                    </div>

                </div>
            }
            {isAdmin &&
                <div className="flex py-5 border-b border-transparent border-b-black border-solid flex-col gap-5 ">
                    <span className="self-start font-bold">Driver Reassigned By Admin Log</span>
                    <div className="grid grid-cols-7 gap-3">
                        <span className="col-span-3 font-bold">Select Driver to Reassign</span>
                        <span className="font-bold">Continued From</span>
                        <span className="font-bold">Time of Reassignment</span>
                        <span className="font-bold">Date of Reassignment</span>

                        <Form onFinish={_driverReassign} form={driverReassignForm} layout='vertical' className="col-span-3 flex flex-row gap-3 items-end">
                            <MYCRemoteSearchSelect onlyActive name="driverId" labelDef={d => d?.user?.name + " (" + d?.user?.email + ")"} label="Driver" url={Endpoints.ALL_DRIVERS} searchAttr={"driverName"} className="flex-1" required />
                            <Button loading={driverReRq?.loading} htmlType="submit" type="primary">Save</Button>
                        </Form>
                        <span>{reassignedStatus?.reassignedAt !== null ? TRIP_STATUSES[reassignedStatus?.reassignedAt] : "Not Reassigned Yet"}</span>
                        <span className=""> {reassignedStatus ? new Date(reassignedStatus?.createdAt).toLocaleTimeString() : "Not Reassigned Yet"}</span>
                        <span className=""> {reassignedStatus ? new Date(reassignedStatus?.createdAt).toLocaleDateString() : "Not Reassigned Yet"}</span>


                    </div>
                </div>
            }
            <div className="flex py-5 border-b border-transparent border-b-black border-solid flex-col gap-5 ">
                <span className="self-start font-bold">Client Auto Message Status</span>
                <div className="grid grid-cols-3 gap-5">
                    <span className="font-bold">Driver Acceptance Message</span>
                    <span className="font-bold">Driver Started Trip Message</span>
                    <span className="font-bold">Driver Completed Trip Message</span>
                    <span className={trip?.driverAcceprtanceMessage ? "text-green-500" : "text-red-500"}>{trip?.driverAcceprtanceMessage ? "Sent" : "Pending"}</span>
                    <span className={trip?.driverStartedTripMessage ? "text-green-500" : "text-red-500"}>{trip?.driverStartedTripMessage ? "Sent" : "Pending"}</span>
                    <span className={trip?.tripCompletedMessage ? "text-green-500" : "text-red-500"}>{trip?.tripCompletedMessage ? "Sent" : "Pending"}</span>
                </div>

            </div>
            <div className="flex py-5 border-b border-transparent border-b-black border-solid flex-col gap-5 ">
                <span className="self-start flex-bold">Trip Order Live Status</span>

                <span className="font-bold">Trip Status <span className="text-red-500">{TRIP_STATUSES[trip?.status]}
                </span></span>
                <div className="flex flex-row justify-between px-5 mt-4">
                    <span className={trip?.status === TripStatus.DriverAccepted ? "text-red-500" : ""}>1 - Order Accepted</span>
                    <span>2 - Pre Inspection</span>
                    <span className={trip?.status === TripStatus.TripStarted ? "text-red-500" : ""}>3 - On Route</span>
                    <span className={trip?.status === TripStatus.PostInspection ? "text-red-500" : ""} >4 - Post Inspection</span>
                    <span className={trip?.status === TripStatus.Completed ? "text-red-500" : ""} >5 - Order Completed</span>
                </div>
                <div className="flex flex-row justify-end">
                    {(isAdmin && trip?.status !== TripStatus.Completed && trip?.status !== TripStatus.Cancelled) && <Button onClick={setCancelModal.bind(this, true)} type="primary" className="px-5">{trip?.status === TripStatus.Cancelled ? "View Cancellation Reason" : "Cancel Ride"}</Button>}
                </div>

            </div>
            <div className="flex flex-col gap-5 py-5">
                <div className="flex flex-row gap-8">
                    {trip?.status !== TripStatus.Cancelled && <>
                        <InspectionButton rq={preRq} trip={trip} post={false} />
                        <InspectionButton rq={postRq} trip={trip} post={true} />
                    </>}
                    {trip?.status !== TripStatus.Cancelled && <>
                        <CompareButton rq={preRq} postRq={postRq} trip={trip} post={false} />
                    </>}
                    {(trip?.status === TripStatus.Cancelled) ? <div className="flex flex-col gap-5">
                        <span className="font-bold">Cancellation Reason</span>
                        <div className="flex flex-row gap-5">
                            <img src={Assets.EyeSquare} onClick={setCancelModal.bind(this, true)} className="cursor-pointer w-6 h-6" />
                            <PDFDownloadLink fileName={`MYC-${trip?.id}-` + "cancel-reason.pdf"} document={<CancelDoc trip={trip} acceptedStatus={acceptedStatus ?? reassignedStatus} />}>
                                {({ loading, url }) => {
                                    return <div className="flex flex-row gap-2">
                                        {loading ? <img src={Assets.Loading} className="w-6 h-6" /> : <img src={Assets.DownloadSquare} className="w-6 h-6" />
                                        }</div>
                                }}
                            </PDFDownloadLink>
                        </div>
                    </div> : <div />
                    }

                    {trip?.status !== TripStatus.Cancelled && <>

                        <div className="flex flex-col gap-5">
                            <span className="font-bold">Trip Order</span>

                            <PDFDownloadLink fileName={`MYC-${trip?.id}-` + "trip-order.pdf"} document={<TripOrderDoc tripOrder={trip} acceptedStatus={acceptedStatus ?? reassignedStatus} />}>
                                {({ loading, url }) => {
                                    return <div className="flex flex-row gap-2">
                                        {loading ? <img src={Assets.Loading} className="w-6 h-6" /> : <img src={Assets.DownloadSquare} className="w-6 h-6" />
                                        }</div>
                                }}
                            </PDFDownloadLink>
                        </div>
                        <div className="flex flex-col gap-5">
                            <span className="font-bold">Complete Trip Order</span>
                            {/* <PDFDownloadLink fileName={`MYC-${trip?.id}-` + "complete.pdf"} document={<CompleteDoc trip={trip} acceptedStatus={acceptedStatus ?? reassignedStatus} inspection={preRq} postInspection={postRq} />}>
                                {({ loading, url }) => {
                                    return <div className="flex flex-row gap-2">
                                        {loading ? <img src={Assets.Loading} className="w-6 h-6" /> : <img src={Assets.DownloadSquare} className="w-6 h-6" />
                                        }</div>
                                }}
                            </PDFDownloadLink> */}
                            <div className="flex flex-row gap-2">
                                <img src={Assets.DownloadSquare} onClick={() => generatePdfDocument({ data: { trip, acceptedStatus: acceptedStatus ?? reassignedStatus, inspection: preRq, postInspection: postRq }, fileName: `MYC-${trip?.id}-` + "complete.pdf" })} className="cursor-pointer w-6 h-6" />
                            </div>
                        </div>
                    </>}
                </div>
            </div>
        </>}
        {/* <InspectionModal open={postInspect} tripOrderId={trip?.id} postInspect title="Post Inspection Checklist" onCancel={setPostInspect.bind(this, false)} /> */}
        <Modal open={cancelModal} onCancel={setCancelModal.bind(this, false)} footer={null} title="Cancel Trip">
            {trip?.status === TripStatus.Cancelled ? <div className="flex flex-col gap-5">
                <span>{trip?.cancelledTrips[0]?.reason}</span>
                {trip?.cancelledTrips[0]?.image && <img src={getResourceUrl(trip?.cancelledTrips[0]?.image)} className="object-contain w-64 h-64 self-center" />}

            </div> :
                <Form form={cancelForm} onFinish={_cancelTripOrder} layout="vertical">
                    <div className="flex flex-col gap-5">
                        <MYCTextArea name="description" label="Cancel Reason" required />
                        <Button htmlType="submit" loading={cancelRq?.loading} type="primary" className="self-end">Submit</Button>
                    </div>
                </Form>}
        </Modal>
        <Modal open={rcvsModal} onCancel={setRcvsModal.bind(this, false)} footer={null} title="Message Recipients">
            <MYCTable
                {...tripOrderMessages}
                cols={[
                    {
                        id: "created",
                        dataIndex: "createdAt",
                        render: text => new Date(text).toLocaleString(),
                        title: "Sent At"
                    },
                    {
                        id: "rcv",
                        dataIndex: "driverId",
                        render: (text, record) => {
                            if (record?.driverId) {
                                return record?.driver?.user?.name;
                            }
                            return "All Drivers In Region -  " + record?.origin?.name;
                        },
                        title: "Sent To"
                    },
                ]}
            />
        </Modal>
    </div>
}