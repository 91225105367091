import { Button, Form, Modal } from 'antd';
import React, { useState } from 'react';
import { Assets } from '../../assets';
import { CloseCircleFilled } from '@ant-design/icons';
import { MYCInput, MYCTextArea } from '../../components/input_form.component';
import { Colors } from '../../constants/colors.constants';
import { SuccessModal } from '../../components/success_modal.component';
import { useForm } from 'antd/es/form/Form';
import { usePost } from '../../hooks/useRest.hook';
import { Endpoints } from '../../constants/endpoint.constant';

export const ContactScreen = () => {
    const [form] = useForm();
    const submitRq = usePost(Endpoints.POST_CONTACT_US);
    const [modal, setModal] = useState(false);

    const _onFinish = async values => {
        try {
            let data = await submitRq.post(values);
            setModal(true);
        } catch(e) {

        }
    }
    return <>
        <span className='self-center text-lg'>Contact Us</span>
        <Form form={form} onFinish={_onFinish} className='w-1/3 gap-5 self-center flex flex-col' layout='vertical'>
            <MYCInput name="name" label={"Name"} required />
            <MYCInput name="email" label={"Email"} required />
            <MYCInput name="number" label={"Contact Number"} required />
            <MYCTextArea name="message" label={"Message"} required />
            <Button loading={submitRq?.loading} htmlType='submit' className='px-10 self-center' type='primary'>Send</Button>
        </Form>
        <SuccessModal message="Message sent to admin" open={modal} onClose={setModal?.bind(this,false)}/>

    </>
}