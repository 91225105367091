import React from 'react'
import { Assets } from '../assets'
export const PushNotificationHistoryCols = ({viewTo, viewDetail}) => [

    {
        title: "Message",
        wrap: true,
        dataIndex: "description",
        width: "40%",
        key: "message"
    },
    {
        title: "Time",
        wrap: true,
        dataIndex: "createdAt",
        render: text => new Date(text).toLocaleTimeString(),
        width: "10%",
        key: "time"
    },
    {
        title: "Date",
        wrap: true,
        dataIndex: "createdAt",
        render: text => new Date(text).toLocaleDateString(),
        width: "10%",
        key: "date"
    },
    {
        title: "Recipients",
        dataIndex: "date",
        render: (text, obj) => <div onClick={viewTo?.bind(this,obj)} className="cursor-pointer flex justify-center items-center"> <img src={Assets.EyeSquare} /></div>,
        width: "5%",
        key: "to"
    },
    {
        title: "Action",
        dataIndex: "date",
        render: (text, obj) => <div className="cursor-pointer flex flex-row gap-3 justify-center items-center">
            <img onClick={viewDetail?.bind(this,obj)} src={Assets.EyeSquare} />
            {/* <img src={Assets.BinSquare} /> */}
        </div>,

        width: "5%",
        key: "action"
    },


]
export const PushNotificationHistoryData = [
    {
        message: "A driver just accepted your trip order!",
        date: "04/02/2023",
        time: "10:00am"
    },
    {
        message: "A driver just accepted your trip order!",
        date: "04/02/2023",
        time: "10:00am"
    },
    {
        message: "A driver just accepted your trip order!",
        date: "04/02/2023",
        time: "10:00am"
    },
]