import { Button, Select } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Assets } from '../assets';
import { Endpoints, getResourceUrl } from '../constants/endpoint.constant';
import { useNavigate } from 'react-router-dom';
import { useGet, usePost } from '../hooks/useRest.hook';
import { ClientStatus, TRIP_STATUSES, TripStatus } from '../constants/general.constant';
const ID = "IDCard", LICENSE = "License", DISC = 'Disaplinary', PROOF_ADDR = 'ProofAddress', STATUS = "Status";
export const DriverCard = ({ driver, refresh,totalTripCount }) => {
    const navigate = useNavigate();
    const inputRef = useRef();
    const [expanded, setExpanded] = useState(false);
    const uploadRq = usePost(Endpoints.UPDATE_DRIVER(driver?.id));
    const tripRq = useGet(Endpoints.GET_DRIVER_TRIP(driver?.id), false);
    useEffect(() => {
        if (expanded) {
            tripRq?.load();
        }
    }, [expanded])
    let fileType = useRef(-1);
    const openFile = (type) => {
        fileType.current = type;
        inputRef.current.click();
    }
    const uploadFile = async (e) => {
        console.trace("hello file type:", fileType.current);
        if (fileType.current === -1) return;

        try {
            let formData = new FormData();
            if (fileType.current !== STATUS)
                formData.append(fileType.current, e.target.files[0]);
            else formData.append(fileType.current, e);
            let data = await uploadRq.put(formData);
            refresh();
        } catch (e) {

        }
    }
    return <div className="border px-16 py-5 border-black border-solid flex flex-col">
        <div className={`border-b border-transparent ${expanded ? "border-b-black border-solid" : ""} gap-5  flex flex-row items-center`}>
            <div className="w-18 h-18 rounded-full overflow-hidden">
                <img className="w-16 h-16 object-contain" src={getResourceUrl(driver?.user?.picutre)} />
            </div>

            <div className="grid   grid-cols-5 py-5 gap-5 flex-1">
                <span className="font-bold">Driver name</span>
                <span className="font-bold">Region</span>
                <span className="font-bold">Number</span>
                <span className="font-bold">Email</span>
                <span className="font-bold">Actions</span>
                <span>{driver?.user?.name}</span>
                <span>{driver?.user?.origin?.name}</span>
                <span>{driver?.user?.number}</span>
                <span>{driver?.user?.email}</span>
                <div className="flex flex-row gap-2">
                    <img src={Assets.EditSquare} onClick={navigate?.bind(this, `/create-driver/${driver?.userId}`)} className=" cursor-pointer w-6 h-6" />
                </div>
                <div />
                <div />
                <div />
                <div />
                <Button onClick={setExpanded.bind(this, !expanded)} type="primary">View {expanded ? "Less" : "More"}</Button>
            </div>
        </div>
        {expanded && <>
            <div className="flex py-5 border-b border-transparent border-b-black border-solid flex-col gap-5 ">
                <input type="file" hidden ref={inputRef} onChange={uploadFile} />
                <span className="self-start font-bold">Driver Personal Information</span>
                <div className="grid grid-cols-6 gap-5">
                    <span className="font-bold">ID</span>
                    <span className="font-bold">License</span>
                    <span className="font-bold">Proof of Address</span>
                    <span className="font-bold">Disciplinary</span>
                    <span className="font-bold">Number of Completed Trips</span>
                    <span className="font-bold">Registration Status</span>

                    <div className="flex flex-row gap-2">
                        {driver?.idCard && <img onClick={driver?.idCard && (() => window.open(getResourceUrl(driver?.idCard), "_blank"))} src={Assets.EyeSquare} className="w-6 h-6" />
                        }<img onClick={() => {
                            openFile(ID);
                        }} src={Assets.EditSquare} className="w-6 h-6" />
                    </div>
                    <div className="flex flex-row gap-2">
                        {driver?.license && <img onClick={driver?.idCard && (() => window.open(getResourceUrl(driver?.license), "_blank"))} src={Assets.EyeSquare} className="w-6 h-6" />
                        }<img onClick={() => {
                            openFile(LICENSE);
                        }} src={Assets.EditSquare} className="w-6 h-6" />
                    </div>
                    <div className="flex flex-row gap-2">
                        {driver?.proofAddress && <img onClick={driver?.idCard && (() => window.open(getResourceUrl(driver?.proofAddress), "_blank"))} src={Assets.EyeSquare} className="w-6 h-6" />
                        }<img onClick={() => {
                            openFile(PROOF_ADDR);
                        }} src={Assets.EditSquare} className="w-6 h-6" />
                    </div>
                    <div className="flex flex-row gap-2">
                        {driver?.disaplinary && <img onClick={driver?.idCard && (() => window.open(getResourceUrl(driver?.disaplinary), "_blank"))} src={Assets.EyeSquare} className="w-6 h-6" />
                        }<img onClick={() => {
                            openFile(DISC);
                        }} src={Assets.EditSquare} className="w-6 h-6" />
                    </div>
                    <span>{totalTripCount}</span>
                    <Select
                        loading={uploadRq?.loading}
                        defaultValue={driver?.status}
                        options={ClientStatus.map(M => ({ label: M.label, value: M.id }))}
                        placeholder="Driver Status"
                        onChange={(e) => {
                            fileType.current = STATUS;
                            uploadFile(e);
                        }
                        }
                    />
                </div>
            </div>
            {tripRq?.data?.id &&
                <div className="flex flex-col gap-5 py-5">
                    <span className="self-start flex-bold">Active Trip</span>
                    <div className="p-7 border border-solid flex flex-col gap-5">
                        <div className="grid grid-cols-7 gap-5">
                            <span className="font-bold">Vehicle Reg No</span>
                            <span className="font-bold">Pickup Location</span>
                            <span className="font-bold">Dropoff Location</span>
                            <span className="font-bold">Start Date</span>
                            <span className="font-bold">Start Time</span>
                            <span className="font-bold">Total KM</span>
                            <span className="font-bold">MYC Ref No</span>
                            <span>{tripRq?.data?.vehicleRegNumber}</span>
                            <span>{tripRq?.data?.pickUp}</span>
                            <span>{tripRq?.data?.dropOff}</span>
                            <span>{new Date(tripRq?.data?.startDateTime).toLocaleDateString()}</span>
                            <span>{new Date(tripRq?.data?.startDateTime).toLocaleTimeString()}</span>
                            <span>{tripRq?.data?.totalKM?.toFixed?.(0)}</span>
                            <span>MYC#{tripRq?.data?.id}</span>
                        </div>
                        <div className="flex py-5 border-b border-transparent border-b-black border-solid flex-col gap-5 ">
                            <span className="self-start flex-bold">Trip Order Live Status</span>

                            <span className="font-bold">Trip Status <span className="text-red-500">{TRIP_STATUSES[tripRq?.data?.status]}
                            </span></span>
                            <div className="flex flex-row justify-between px-5 mt-4">
                                <span className={tripRq?.data?.status === TripStatus.DriverAccepted ? "text-red-500" : ""}>1 - Order Accepted</span>
                                <span>2 - Pre Inspection</span>
                                <span className={tripRq?.data?.status === TripStatus.TripStarted ? "text-red-500" : ""}>3 - On Route</span>
                                <span className={tripRq?.data?.status === TripStatus.PostInspection ? "text-red-500" : ""} >4 - Post Inspection</span>
                                <span className={tripRq?.data?.status === TripStatus.Completed ? "text-red-500" : ""} >5 - Order Completed</span>
                            </div>
                           

                        </div>
                    </div>
                </div>}
        </>}
    </div>
}