import { Button, Form } from 'antd';
import React from 'react';
import { MYCDate, MYCRemoteSearchSelect, MYCSearch, MYCSelect } from '../../components/input_form.component';
import { MYCPie } from '../../components/pie_graph.component';
import { useState } from 'react';
import { useGet } from '../../hooks/useRest.hook';
import { Endpoints } from '../../constants/endpoint.constant';
import { useForm } from 'antd/es/form/Form';

export const AdminGraphScreen = () => {
    const [query, setQuery] = useState({});
    const originsRq = useGet(Endpoints.GET_ORIGINS);
    const [form] = useForm();
    const graphRq = useGet(Endpoints.ADMIN_ANALYTICS,true,query);

    const _onSubmit = values => {
        setQuery(values);
    }

    return <>

        <span className='self-center text-xl'>Analytics Dashboard</span>
        <Form form={form} onFinish={_onSubmit} layout='vertical'>
            <div className='flex w-4/5 my-10 flex-row gap-5 items-end justify-start'>
                <MYCRemoteSearchSelect multiple   name="clientId" labelDef={d => (d?.user?.name)} url={Endpoints.ALL_CLIENTS}  className="flex-2" label="Search By Client" />
                <MYCSelect options={
                    [{label:"All", value:null},...(originsRq?.data?.map?.(M => ({ value: M.id, label: M.name })) ?? [])]
                } name="originId" className="flex-1" label={"Region"}  />
                <MYCDate
                    name="fromDateTime"
                    showTime={false}
                    className={"flex-1"}
                    label="From Date"
                />
                <MYCDate
                    name="toDateTime"
                    showTime={false}
                    className={"flex-1"}
                    label="To Date"
                />
                <Button htmlType="submit" loading={graphRq?.loading} className='flex-1' type='primary'>Search</Button>
            </div>
        </Form>
        <div className='grid grid-cols-4 gap-5'>
            <MYCPie className={"h-72"} label="Total Customers"
                data={[

                    {
                        name: "Recieved",
                        value: graphRq?.data?.totalCustomers,
                        primary: true
                    }
                ]}
            />  
            <MYCPie className={"h-72"} label="Total Trips Pending"
                data={[
                    {
                        name: "Total",
                        value: graphRq?.data?.totalOrders -  graphRq?.data?.totalPendingOrders
                    },
                    {
                        name: "Pending",
                        value: graphRq?.data?.totalPendingOrders,
                        primary: true
                    }
                ]}
            />
            <MYCPie className={"h-72"} label="Total Trips Completed"
                data={[

                    {
                        name: "Completed",
                        value: graphRq?.data?.totalCompletedOrders,
                        primary: true
                    }, {
                        name: "Total",
                        value: graphRq?.data?.totalOrders - graphRq?.data?.totalCompletedOrders
                    },
                ]}
            />
            <MYCPie className={"h-72"} label="Total Trips Canceled"
                data={[

                    {
                        name: "Cancelled",
                        value: graphRq?.data?.totalCancelledOrders,
                        primary: true
                    }, {
                        name: "Total",
                        value: graphRq?.data?.totalOrders - graphRq?.data?.totalCancelledOrders
                    },
                ]}
            />
            <MYCPie className={"h-72"} label="Total Drivers"
                data={[

                    {
                        name: "Drivers",
                        value: graphRq?.data?.totalDrivers,
                        primary: true
                    }
                ]}
            />
            <MYCPie fixedCircle={"R" + graphRq?.data?.totalTripInvoice?.toFixed?.(2)} className={"h-72"} label="Total Trips Invoice"
                data={[

                    {
                        name: "Total Trips Invoice",
                        value: 2,
                        primary: true
                    },
                    {
                        name: "Total",
                        value:0
                    }
                ]}
            />
            <MYCPie fixedCircle={graphRq?.data?.kmTraveled?.toFixed?.(0)} className={"h-72"} label="Total KM"
                data={[

                    {
                        name: "Total KM",
                        value: 2,
                        primary: true
                    },
                    {
                        name: "Total",
                        value:0
                    }
                ]}
            />
        </div>
    </>
}