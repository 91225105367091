import { Button } from 'antd';
import React from 'react';
import { Assets } from '../assets';
import { ClientStatus } from '../constants/general.constant';

export const ClientCols = ({ setKmModal,navigate }) => [
    {
        title: "Entity Name",
        dataIndex: ["user","name"],
        width: '10%',
        render: (text, record) => (record?.user?.name),
        key: "name",
    },
    {
        title: "Client Full Name",
        dataIndex: ["user","name"],
        width: '10%',
        render: (text, record) => (record?.user?.surname),
        key: "name",
    },
    {
        title: "Location",
        dataIndex: ["user","origin","name"],
        width: '10%',
        key: "location",
    },
    {
        title: "Number",
        dataIndex: ["user","number"],
        width: '10%',
        key: "number",
    },
    {
        title: "Status",
        dataIndex: "status",
        width: '10%',
        render: (text) => ClientStatus[text]?.label,
        key: "status",
    },
    {
        title: "Email",
        dataIndex: ["user","email"],
        width: '10%',
        key: "email",
    },
    {
        title: "Price Per KM",
        dataIndex: "pricePerKM",
        width: '15%',
        key: "ppkm",
        wrap: true,
        render: (text,record) => <div className="flex flex-row justify-between items-center gap-3">
            R{text}
            <Button onClick={setKmModal?.bind(this, record?.id)} type="primary">Update</Button>
        </div>
    },
    {
        title: "Action",
        dataIndex: "id",
        width: '5%',
        wrap: true,
        render: (text) => <div className="cursor-pointer flex flex-row gap-3 justify-center items-center">
            <img className="cursor-pointer" onClick={() => navigate("/create-client/"+text)} src={Assets.EditSquare} />
            {/* <img src={Assets.BinSquare} /> */}
        </div>,
        key: "action",
    },
]

export const ClientData = [
    {
        name: "Avis",
        location: "Cape Town",
        number: "2910382190",
        status: "Active",
        email: "avis@mail.com",
        "pricePerKm": 10
    },
    {
        name: "Budget",
        location: "Durban",
        number: "2910382190",
        status: "Pending",
        email: "avis@mail.com",
        "pricePerKm": 10
    },
    {
        name: "Avis",
        location: "Cape Town",
        number: "2910382190",
        status: "Active",
        email: "avis@mail.com",
        "pricePerKm": 10
    },
    {
        name: "Budget",
        location: "Durban",
        number: "2910382190",
        status: "Pending",
        email: "avis@mail.com",
        "pricePerKm": 10
    },
]