import { Button, Form } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { MYCInput, MYCSelect } from '../../components/input_form.component';
import { useForm } from 'antd/es/form/Form';
import { useGet, usePost } from '../../hooks/useRest.hook';
import { Endpoints, getResourceUrl } from '../../constants/endpoint.constant';
import { useNavigate, useParams } from 'react-router-dom';
import { Assets } from '../../assets';
import { SuccessModal } from '../../components/success_modal.component';
import { toast } from 'react-toastify';

export const CreateDriverScreen = () => {
    const [form] = useForm();
    const { id } = useParams();
    const [passForm] = useForm();
    const passRq = usePost(Endpoints.UPDATE_USER_PASSWORD(id));

    const userRq = useGet(Endpoints.GET_USER(id), !!id);
    const createRq = usePost(!!id ? Endpoints.UPDATE_USER : Endpoints.CREATE_DRIVER);
    const navigate = useNavigate();
    const imgRq = usePost(Endpoints.UPDATE_USER_PIC);
    const [suc, setSucModal] = useState(false);

    const originsRq = useGet(Endpoints.GET_ORIGINS);
    const [ img, setImg ] = useState(null);
    const inputRef = useRef();
    const _onUpdatePassword = async values => {
        try {
            let data = await passRq?.post(values);
            setSucModal("Password Updated Succesfully");

        } catch {

        }
    }
    const readURL = (input) => {
        return new Promise((resolve, reject) => {
            console.log('read', input);
            if (input.target.files && input.target.files[0]) {
                var reader = new FileReader();

                reader.onload = function (e) {
                    resolve(e.target.result);
                };

                reader.readAsDataURL(input.target.files[0]);
            }
        });
    }
    useEffect(() => {
        if (userRq?.data?.id) {
            form.setFieldsValue(userRq?.data);
        }
    }, [userRq?.data])

    const _onFinish = async values => {
        try {
            if (inputRef?.current?.files?.length > 0) {
                var size = inputRef?.current?.files[0].size;
                const kbytes = Math.round((size / 1024));
                if (kbytes > 3072) {
                    toast.error("Image is greater than 3MB, please select a image smaller than 3MB! ");
                    return;
                }
            }
            let payload = values;
            if (id) {
                payload = userRq?.data;
                Object.assign(payload, values);
            }
            var data = await createRq.post(payload);
            if (inputRef?.current?.files?.length > 0) {
                
                let formData = new FormData();
                formData.append("id", id || data?.id);
                formData.append("image", inputRef?.current?.files[0]);
                await imgRq?.put(formData);
            }
            navigate("/drivers", {
                replace: true
            });
        } catch (e) {
            console.error("[CREATE DRIVER] error", e);
        }
    }

    return <>
        <span className='self-center text-xl my-5'>{id ? "Update" : "Create"} Driver</span>
        <input type="file" ref={inputRef} onChange={(e) => readURL(e).then(e => setImg(e))} hidden />
        <SuccessModal message={suc} open={suc} onClose={setSucModal?.bind(this, false)} />
        <Form form={form} onFinish={_onFinish} className=' px-24' layout='vertical'>
            <div className='grid grid-cols-2 gap-5'>
                <MYCInput name="username" label={"Driver Username"} required />
                <MYCInput name="name" label={"Driver Name"} required />
                <MYCInput name="surname" label={"Driver Surname"} required />
                {!id && <MYCInput name="password" label={"Driver Password"} type="password" required />}
                <MYCSelect options={
                    originsRq?.data?.map?.(M => ({ value: M.id, label: M.name }))
                } name="originId" label={"Region"} required />                <MYCInput name="number" label={"Number"} required />
                <MYCInput name="email" label={"Email"} required />
                <div className="flex gap-5 items-center flex-col">
                    <div className="rounded-full border flex flex-col items-center justify-center border-solid border-b overflow-hidden w-32 h-32 ">
                        <img src={img || (userRq?.data?.picutre ? getResourceUrl(userRq?.data?.picutre) : false) || Assets.Upload} className={`object-contain ${img || userRq?.data?.picutre ? "w-full h-full" : "w-10 h-10"}`} />
                    </div>
                    <Button type="primary" onClick={() => inputRef?.current?.click()}>Upload Image</Button>
                </div>
            </div>
            <div className='mt-5 flex flex-row justify-end items-end'>
                <Button loading={createRq?.loading || imgRq?.loading} htmlType='submit' type='primary' className=' w-1/3'>{id ? "Update" : "Create"} Driver</Button>
            </div>
        </Form>
        {id && <>
            <span className='self-center text-center text-xl my-5'>Update Password</span>
            <Form onFinish={_onUpdatePassword} form={passForm} layout="vertical">

                <div className='grid grid-cols-2 py-10 gap-5 px-24'>

                    <MYCInput type="password" name="UpdatedPassword" label="Enter Password" />
                    <MYCInput type="password" name="ConfirmPassword" label="Confirm Password" />
                    <div />
                    <Button loading={passRq?.loading} htmlType='submit' type='primary' className=' w-1/2'>{!id ? "Create" : "Update"} Password</Button>
                </div>
            </Form>
        </>}
    </>
}