import React from 'react';
import { Button } from "antd";

export const AccidentCols = (navigate) => [
    {
        title:"ID",
        dataIndex:'tripOrderId',
        render: text => 'MYC'+text,
        key:"id",
        width:'15%'
    },
    {
        title: "Vehicle Make",
        dataIndex:'vehicleMakeModel',
        key:'from',
        wrap:true,
        width:'15%'
    },
    {
        title: "Ref Number",
        dataIndex:['tripOrder','clientReferenceNumber'],
        key:'from',
        wrap:true,
        width:'15%'
    },
    {
        title: "Time",
        dataIndex:'accidentDateTime',
        key:'from',
        render: text => new Date(text).toLocaleTimeString(),
        width:'15%'
    },
    {
        title: "Date",
        dataIndex:'accidentDateTime',
        render: text => new Date(text).toLocaleDateString(),
        key:'from',
        width:'15%'
    },
    {
        title: "View",
        dataIndex:'id',
        render: (text, record) => <>
            <Button onClick={() => navigate(`/accident-details/${text}`)} type='primary'>View More</Button>
        </>,
        key:'actions',
        width:'20%'
    },
]

export const AccidentData = [
    {
        id: 1,
        from: "John Doe",
        message: "Please view this message",
        time: "12:35 PM",
        date: "20/02/2023",
    },
    {
        id: 1,
        from: "John Doe",
        message: "Please view this message",
        time: "12:35 PM",
        date: "20/02/2023",
    },
]