import { CloseCircleFilled } from '@ant-design/icons';
import { Modal } from 'antd';
import React from 'react';
import { Assets } from '../assets';

export const SuccessModal = ({ message, open, onClose }) => <Modal title="" open={open} closeIcon={<CloseCircleFilled className='text-primary-orange' />} footer={null} onCancel={onClose} >
    <div className='flex flex-col items-center'>
        <img src={Assets.TickCircle} className="w-48 h-48 object-contain self-center" />
        <span className="text-lg">{message}</span>
    </div>
</Modal>