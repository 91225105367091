import { Button, Form } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { MYCDate, MYCInput, MYCPlacesSelect, MYCRemoteSearchSelect, MYCTimePicker } from '../../components/input_form.component';
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";

import { TripForm } from '../../components/trip_form.component';
import { Constants, TripType, isAdmin } from '../../constants/general.constant';
import { Endpoints } from '../../constants/endpoint.constant';
import { usePost } from '../../hooks/useRest.hook';
import { useNavigate } from 'react-router-dom';
import { SuccessModal } from '../../components/success_modal.component';
const OVERVIEW = 0, CLIENT_FORM = 1, FINISH = 2;


export const NewOrderScreen = () => {
    const [form] = Form.useForm();
    const [step, setStep] = useState(OVERVIEW);
    const formRefs = useRef({});
    console.log(formRefs);
    const [tripOrders, setTripOrders] = useState([]);
    const [successModal, setSuccessModal] = useState(true);
    const completeOrderRq = usePost(Endpoints.UPDATE_ORDER);
    const navigate = useNavigate();

    const onAllTripFormSubmit = async () => {
        try {
            await Promise.all(Object.keys(formRefs.current).map(M => formRefs.current[M].form.validateFields()));
            var values = Object.keys(formRefs.current).map(M => {
                let trip = formRefs.current[M].trip;
                return {
                    ...formRefs.current[M].form.getFieldsValue(),
                    TripOrderId: trip?.id,
                    PickUp: trip?.pickUp,
                    PickUpLatitude: trip?.pickUpLatitude,
                    PickUpLongitude: trip?.pickUpLongitude,
                    DropOff: trip?.dropOff,
                    DropOffLatitude: trip?.dropOffLatitude,
                    DropOffLongitude: trip?.dropOffLongitude
                }
            });
            var data = await completeOrderRq.put(values);
            setStep(FINISH);

        } catch (e) {
            console.log('form error', e);
        }
    }

    const returnTripCount = Form.useWatch('returnTrips', form);
    const orderPostRq = usePost(Endpoints.POST_ORDER)

    const {
        placesService,
        placePredictions,
        getPlacePredictions,
        isPlacePredictionsLoading,
    } = usePlacesService({
        apiKey: Constants.GOOGLE_API_KEY,
    });
    const getPlaceDetails = placeId => {
        return new Promise(async (resolve, reject) => {
            placesService.getDetails({
                placeId: placeId
            }, (data) => {
                if (data) {
                    console.log(data);
                    let name = data?.name;
                    if (name) {
                        let city = data?.address_components.find(F => F.types.findIndex(T => T === "locality") !== -1 && F.types.findIndex(T => T === "political") !== -1)?.long_name;
                        if (city) {
                            name = name + ", " + city;
                        }
                        let admin_region = data?.address_components.find(F => F.types.findIndex(T => T === "administrative_area_level_1") !== -1 && F.types.findIndex(T => T === "political") !== -1)?.long_name;
                        
                        if (admin_region) {
                            name = name + ", " + admin_region;
                        }
                    }
                    return resolve({
                        name: name,
                        lat: data?.geometry?.location?.lat(),
                        lng: data?.geometry?.location?.lng(),
                    })
                }
            })
        })
    }

    const _onFinish = async values => {

        values.PickupLocation = await getPlaceDetails(values.PickupLocation);
        values.DropoffLocation = await getPlaceDetails(values.DropoffLocation);


        let payload = [
            {
                type: TripType.OneWay,
                numberOfTrips: values?.NumberofOneWayTrips,
                startDateTime: values?.StartDate?.$d?.toJSON(),
                pickUp: values?.PickupLocation?.name,
                pickUpLatitude: values?.PickupLocation?.lat,
                pickUpLongitude: values?.PickupLocation?.lng,
                dropOff: values?.DropoffLocation?.name,
                dropOffLatitude: values?.DropoffLocation?.lat,
                dropOffLongitude: values?.DropoffLocation?.lng,
            }

        ]
        if (isAdmin) payload[0].clientId = values?.Client

        if (values.returnTrips > 0) {
            payload.push({
                type: TripType.Return,
                numberOfTrips: values?.returnTrips,
                startDateTime: values?.StartDate?.$d?.toJSON(),
                returnDateTime: values?.returnDateTime?.$d?.toJSON(),
                pickUp: values?.PickupLocation?.name,
                pickUpLatitude: values?.PickupLocation?.lat,
                pickUpLongitude: values?.PickupLocation?.lng,
                dropOff: values?.DropoffLocation?.name,
                dropOffLatitude: values?.DropoffLocation?.lat,
                dropOffLongitude: values?.DropoffLocation?.lng,
                clientId: values?.Client
            })

            if (isAdmin) payload[1].clientId = values?.Client
        }

        try {
            var data = await orderPostRq.post(payload);
            setTripOrders(data);
            setStep(CLIENT_FORM);
        } catch (e) {

        }
    }

    console.log("return trip count ", parseInt(returnTripCount ? returnTripCount : "0") > 0);

    return <>
        <div className='self-center my-10 flex flex-row w-1/3'>
            <div className={`${step === OVERVIEW ? 'bg-primary-orange   text-white' : 'bg-gray-200 text-black '} text-center flex-1 py-3`}>
                Overview
            </div>
            <div className={`${step >= CLIENT_FORM ? 'bg-primary-orange   text-white' : 'bg-gray-200 text-black '} text-center flex-1 py-3`}>
                Client Form
            </div>
        </div>
        {step === OVERVIEW ? <>
            <span className=' w-64 text-center my-10 text-red-500  self-center'>
                <span className='font-bold'> Please note:</span> To ensure times below are
                within reasonable timeframes.
            </span>

            <Form form={form} onFinish={_onFinish} layout='vertical'>

                <div className='grid-cols-2 grid w-2/3 gap-5'>
                    <MYCInput name="NumberofOneWayTrips" label={"Number of trips"} required type='number' min={1} />
                    <MYCDate name="StartDate" className={"w-full"} label={"Enter Date"} required />
                    {isAdmin && <MYCRemoteSearchSelect name="Client" labelDef={d => (d?.user?.name + `   (${d?.user?.email})`)} className="w-full" label="Client" required url={Endpoints.ALL_CLIENTS} />}
                    <MYCPlacesSelect name="PickupLocation" label={"Pickup Location"} required type='text' />
                    <MYCPlacesSelect name="DropoffLocation" label={"Dropoff Location"} required type='text' />

                    <MYCInput name="returnTrips" label={"Shuttle/Pilot"} required type='number' />
                    {parseInt(returnTripCount ? returnTripCount : "0") > 0 && <>
                        <MYCDate showTime={false} className={"w-full"} name="returnDateTime" label={"Enter Return Date"} required />
                    </>}

                </div>
                <div className="w-full mt-5 flex justify-end">
                    <Button loading={orderPostRq?.loading} htmlType={"submit"} type='primary' className='self-end px-16'>Place Order</Button>
                </div>
            </Form>
            <span className=' w-64 text-center  text-red-500  self-center'>
                Please enter  <span className='font-bold'>"0"</span> in number of return trips if there are no return trips
            </span>
        </> : step === CLIENT_FORM ?
            <>
                <div className='grid grid-cols-3 gap-3 w-full'>
                    {tripOrders?.map?.((T, i) => <TripForm onForm={({ form, trip }) => formRefs.current[trip?.id.toString()] = { form, trip }} index={i} trip={T} />)}
                </div>
                <Button type="primary" className="w-1/3 self-end mt-5" onClick={onAllTripFormSubmit} loading={completeOrderRq?.loading}>Submit</Button>
            </>
            : <div className="flex flex-col gap-5 items-center">
                <SuccessModal message="Trip order sent successfully to admin" onClose={setSuccessModal.bind(this, false)} open={successModal} />
                <span>Please check your inbox for status updates</span>
                <Button onClick={() => navigate(isAdmin ? "/active-trip-orders" : "/order-history", {
                    replace: true
                })} type="primary" className="w-72">Continue</Button>
            </div>

        }
    </>
}