import { Assets } from "../assets";
import { Constants, isAdmin } from "./general.constant";
console.log(process.env.REACT_APP_MODE);

const SERVER_URL = "https://api.mycportal.io/";
const LOCAL_URL = "http://localhost:5274/";
const QA_URL = "http://143.198.84.166:3030/"
export const Endpoints = {
    BASE_URL: SERVER_URL + "api",
    ALL_CLIENTS: "/user/get-clients",
    TOKEN_VALIDITY: "/auth/token-validity",
    CREATE_CLIENT: "/user/add-client",
    CREATE_DRIVER: "/user/add-driver",
    ALL_DRIVERS: "/user/get-drivers",
    ALL_DRIVERS_TRIP_COUNT: "/user/get-drivers-with-trips",
    POST_ORDER: isAdmin ? "/order/admin-addTripOrder": "/order/client-addTripOrder",
    ALL_ORDERS: process.env.REACT_APP_MODE === Constants.ADMIN ? "/order/v2/get-paginatedTripOrders" : "/order/v2/get-paginatedClientTripOrders",
    LOGIN: process.env.REACT_APP_MODE === Constants.ADMIN ? "/auth/admin-login" : "/auth/client-login",
    UPDATE_ORDER: "/order/update-tripOrder",
    ASSIGN_DRIVER: (tripId, driverId) => `/order/assign-tripOrder?tripOrderId=${tripId}&driverId=${driverId}`,
    FIND_ORDER_REG_REF: filter => `/order/filter-driverTrackings?filter=${filter}`,
    LATEST_TRACK_DRIVER: tripId => `order/get-driverTrackingsByTripOrderId?tripOrderId=${tripId}`,
    ACCIDENTS: isAdmin? '/inspection/get-accidentReports':'/inspection/get-clientAccidentReports',
    ACCIDENT_REPORT: id => `/inspection/accident-report/${id}`,
    UPDATE_CLIENT_RATE: '/user/update-client-rate',
    POST_CONTACT_US: '/user/contact-us',
    GET_CONTACTS_US: '/user/get-contactUsMessages',
    GET_CONTACT_US_DETAILS: id => '/user/contact-us/' + id,
    RESPONSE_CONTACT_US: id => `/user/contactUs-response?messageId=`+id,
    GET_USER: id => `/user/get-user/${id}`,
    UPDATE_USER: '/user/update-user',
    GET_PRE_INS: id => `/inspection/get-preinspection/${id}`,
    GET_POST_INS: id => `/inspection/get-postinspection/${id}`,
    GET_ORIGINS: '/user/get-origins',
    DRIVER_MSG: '/order/assign-tripOrderMessage',
    TRIP_ASSIGN_HISTORIES: '/order/get-tripOrderAssignmentHistories',
    CANCEL_TRIP_ORDER: id => `/order/admin-cancel-trip/${id}`,
    GET_MESSAGES: (isAdmin?"/order/get-paginatedAdminMsgs":"/order/get-client-messages"),
    SUBMIT_PUSH_NOTI: "/order/add-customMessage",
    ADMIN_ANALYTICS: "/dashboard/admin-analytics",
    CLIENT_ANALYTICS: "/dashboard/client-analytics",
    GET_PUSH_NOTIS: isAdmin ? "/order/get-customMessages": "/order/get-client-messages",
    GET_CLIENT: id => `/user/get-client/${id}`,
    UPDATE_CLIENT: id => `/user/update-client/${id}`,
    UPDATE_DRIVER: id => `/user/update-driver?driverId=${id}`,
    GET_DRIVER_TRIP: id => `/order/get-driverLatestTrip?driverId=${id}`,
    UPDATE_USER_PIC: `/user/update-user-pic`,
    GET_TRIPORDER_MESSAGES: id => ('/order/get-paginatedTripOrderMessages?tripOrderId=' + id),
    DELETE_CONTACT: "/user/delete-contact",
    UNREAD_CONTACT: '/order/unread-count',
    GET_TRIP_ORDER: id => `/order/trip-order/${id}`,
    UPDATE_ACCIDENT_REPORT: id => `/inspection/update-accident-report/${id}`,
    POLYLINE: '/order/polyline',
    UPDATE_USER_PASSWORD: id => `/auth/update-password-admin/${id}`
    
}

export const getResourceUrl = (resource, fallback) =>  {
    if (fallback && !resource) return Assets.LogoDark;
    return SERVER_URL + "files/" + resource;
} 