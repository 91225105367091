import { Button } from 'antd';
import React from 'react';
import { Cell, Label, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { Colors } from '../constants/colors.constants';

export const MYCPie = ({ label, data, className, fixedCircle }) => {
    return <div className={"relative flex flex-col items-center " + className}>
        <span className='self-center text-center text-xl'>{label}</span>
        <ResponsiveContainer>
            <PieChart>
                <Pie
                    data={data}

                    innerRadius={60}
                    outerRadius={80}
                    fill="#8884d8"
                    paddingAngle={5}
                    dataKey="value"

                >

                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry?.primary ? Colors.primaryOrange : "#303030"} />
                    ))}
                </Pie>
            </PieChart>
        </ResponsiveContainer>
        <div className='absolute flex flex-col pt-2 justify-center items-center h-full text-center  px-48'>{fixedCircle ?? data?.find(F => F.primary)?.value}<br />{label}</div>
    </div>
}