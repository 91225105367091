import React from 'react';
import { Pagination, Table } from "antd"


export const MYCTable = ({ load, loading, pageNo, totalCount, pageSize, name, cols, list: data }) => {
    return <Table
        columns={cols}
        pagination={{
            pageSize: pageSize,
            current: pageNo,
            total: totalCount,
            onChange: (pageNo, pageSize) => {
                load(pageNo, pageSize);
            }
        }
        }
        loading={loading}
        dataSource={data}
        style={{ width: '100%' }}
    />
}

export const MYCPagination = ({ load, pageNo, totalCount, pageSize, }) => pageSize && <Pagination
    pageSize={pageSize}
    current={pageNo}
    total={totalCount}
    onChange={(pageNo, pageSize) => {
        load(pageNo, pageSize);
    }}
/>