import { DatePicker, Form, Input, Select, TimePicker } from 'antd';
import React, { useEffect, useState } from 'react';
import { Constants } from '../constants/general.constant';
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useGet, useGetPaginated } from '../hooks/useRest.hook';
import { debounce } from 'debounce';
export const MYCInput = ({ name, label, disabled, hidden, value, labelColor, required, className, noMb, type = "text", min }) => {
    return <Form.Item hidden={hidden} name={name} disabled={disabled} label={<label style={{ color: labelColor || "black" }}>{label}</label>} style={{ color: 'white' }} className={`${noMb ? '' : 'mb-0'} ${className}`} rules={required ? [{ message: `${label} is required`, required: true }, (min?{message: `${label} cannot be less than ${min}`, validator: (rule, value) => new Promise((resolve,reject) => parseInt(value) >= min? resolve(): reject())}:undefined)] : undefined}>
        <Input placeholder={label} disabled={disabled} value={value} type={type} />
    </Form.Item>
}


export const MYCSelect = ({ name, initalValue, label, options, required, className, noMb, type = "text" }) => {
    return <Form.Item name={name} className={`${noMb ? '' : 'mb-0'} ${className}`} label={label} rules={required ? [{ message: `${label} is required`, required: true }] : undefined}>
        <Select
            defaultValue={initalValue}
            options={options}
            placeholder={label}
        ></Select>
    </Form.Item>
}

export const MYCPlacesSelect = ({ name, label,hint, options, required, className, noMb, type = "text" }) => {
    const {
        placesService,
        placePredictions,
        getPlacePredictions,
        isPlacePredictionsLoading,
    } = usePlacesService({
        apiKey: Constants.GOOGLE_API_KEY,
    });

    const handleSearch = (search) => {
        getPlacePredictions({ input: search, })
    }
    return <Form.Item name={name} className={`${noMb ? '' : 'mb-0'} ${className}`} label={label} rules={required ? [{ message: `${label} is required`, required: true }] : undefined}>
        <Select
            showSearch
            placeholder={hint || label}
            loading={isPlacePredictionsLoading}
            defaultActiveFirstOption={false}
            showArrow={false}
            className={hint && "hint"}
            filterOption={false}
            onSearch={handleSearch}
            notFoundContent={null}
            options={(placePredictions || []).map((d) => ({
                value: d.place_id,
                label: d.description,
            }))}
        />
    </Form.Item>
}

export const MYCRemoteSearchSelect = ({ name,onlyActive, label, multiple, disabled, options, required, searchAttr, className, noMb, type = "text", url, labelDef, valueDef }) => {
    const [search, setSearch] = useState('');
    const [query, setQuery] = useState({});
    const searchRq = useGet(url, true, query);
    useEffect(() => {
        console.trace('search', search);
        setQuery({
            [searchAttr || 'search']: search,
            onlyActive
        });
    }, [search])
    const handleSearch = debounce(setSearch, 300);

    return <Form.Item name={name} disabled={disabled} className={`${noMb ? '' : 'mb-0'} ${className}`} label={label} rules={required ? [{ message: `${label} is required`, required: true }] : undefined}>
        <Select
            showSearch
            disabled={disabled}
            placeholder={label}
            className={className}
            mode={multiple ? 'multiple' : ''}
            loading={searchRq?.loading}
            defaultActiveFirstOption={false}
            allowClear
            showArrow={false}
            filterOption={false}
            onSearch={handleSearch}
            notFoundContent={null}
            options={(searchRq?.data?.list || []).map((d) => ({
                value: valueDef?.(d) || d?.id,
                label: labelDef?.(d) || d?.name,
            }))}
        />
    </Form.Item>
}


export const MYCSearch = ({ name, disabled, label, required, className, noMb, type = "text" }) => {
    return <Form.Item name={name} label={label} className={`${noMb ? '' : 'mb-0'} ${className}`} rules={required ? [{ message: `${label} is required`, required: true }] : undefined}>
        <Input.Search disabled={disabled} placeholder={label} type={type} />
    </Form.Item>
}

export const MYCTextArea = ({ name, label, initialValue, required, className, noMb, type = "text" }) => {
    return <Form.Item initialValue={initialValue} name={name} label={label} className={`${noMb ? '' : 'mb-0'} ${className}`} rules={required ? [{ message: `${label} is required`, required: true }] : undefined}>
        <Input.TextArea rows={4} placeholder={label} type={type} r />
    </Form.Item>
}

export const MYCDate = ({ label, required, className, name, showTime = true }) => {
    return <Form.Item label={label} className={`mb-0 ${className}`} name={name} rules={required ? [{ message: `${label} is required`, required: true }] : undefined}>
        <DatePicker placeholder={label} style={{ width: '100%' }} showTime={showTime} />
    </Form.Item>
}

export const MYCTimePicker = ({ label, required, className, name }) => {
    return <Form.Item label={label} className={`mb-0 ${className}`} name={name} rules={required ? [{ message: `${label} is required`, required: true }] : undefined}>
        <TimePicker use12Hours style={{ width: '100%' }} placeholder={label} className={className} />
    </Form.Item>
}
