import { Button, Form } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { MYCDate, MYCInput, MYCTextArea, MYCTimePicker } from '../../components/input_form.component';
import { useGet, usePost } from '../../hooks/useRest.hook';
import { Endpoints, getResourceUrl } from '../../constants/endpoint.constant';
import { useParams } from 'react-router-dom';
import { useForm } from 'antd/es/form/Form';
import moment from 'moment'
import { Assets } from '../../assets';
import dayjs from 'dayjs';
import { SuccessModal } from '../../components/success_modal.component';

const ChoiceView = ({ label, yes, yesLabel = "Yes", noLabel = "No", update = () => console.log('no callback') }) => {
    return <div className="flex flex-col gap-3 items-center">
        <span>{label}</span>
        <div className="flex flex-row justify-between gap-10 px-10 py-5 ">
            <div className="flex items-center flex-row gap-2">
                <span>{yesLabel}</span>
                <div onClick={update.bind(this, true)} className={`${yes ? "bg-primary-orange" : "border border-solid"}  w-5 cursor-pointer h-5 `}></div>
            </div>
            <div className="flex items-center flex-row gap-2">
                <span>{noLabel}</span>
                <div onClick={update.bind(this, false)} className={`${!yes ? "bg-primary-orange" : "border border-solid"}  w-5 h-5 cursor-pointer `}></div>
            </div>
        </div>
    </div>
}


export const CreateAccidentReport = () => {
    const { id } = useParams();
    const detailsRq = useGet(Endpoints.ACCIDENT_REPORT(id));
    const updateRq = usePost(Endpoints.UPDATE_ACCIDENT_REPORT(id));


    const [img, setImg] = useState(null);
    const [vehicleTowed, setVTow] = useState(false);
    const [policeScene, setPoliceScene] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const inputRef = useRef();
    const readURL = (input) => {
        return new Promise((resolve, reject) => {
            console.log('read', input);
            if (input.target.files && input.target.files[0]) {
                var reader = new FileReader();

                reader.onload = function (e) {
                    resolve(e.target.result);
                };

                reader.readAsDataURL(input.target.files[0]);
            }
        });
    }
    const [form] = useForm();
    useEffect(() => {
        if (detailsRq?.data?.id) {
            form.setFieldsValue({
                ...detailsRq?.data,
                licExpiry: dayjs(detailsRq?.data?.licExpiry),
                accidentDateTime: dayjs(detailsRq?.data?.accidentDateTime)
            });
            setVTow(detailsRq?.data?.vehicleTowed);
            setPoliceScene(detailsRq?.data?.policeScene);
        }
    }, [detailsRq?.data])

    const _onFinish = async values => {
        values.vehicleTowed = vehicleTowed ? "1" : "0";
        values.policeScene = policeScene ? "1" : "0";
        console.log(values);
        try {
            let formData = new FormData();
            Object.keys(values).forEach(K => {
                if (K === "accidentDateTime" || K === "licExpiry") {
                    
                    formData.append(K, values[K]?.$d?.toJSON?.());
                } else
                    formData.append(K, values[K]);
            })

            if (inputRef.current.files[0]) {
                formData.append("sketch", inputRef.current.files[0])
            }

            var data = await updateRq?.put(formData);
            detailsRq?.load?.();
            setSuccessModal("Accident Report Updated Successfully");
        } catch {

        }
    }

    return <>
        <span className="self-center text-xl">
            Update Accident Report
        </span>
        <input type="file" ref={inputRef} onChange={(e) => readURL(e).then(e => setImg(e))} hidden />
        <SuccessModal open={successModal} message={successModal} onClose={setSuccessModal?.bind(this,false)}/>
        <Form form={form} onFinish={_onFinish} layout="vertical">
            <div className="w-2/3 mx-auto gap-5 flex flex-col">
                <MYCInput name="driverName" label="Name" required />
                <MYCInput name="driverSurname" label="Surname" required />
                <MYCInput name="drId" label="ID" required />
                <MYCDate name="licExpiry" label="License Exp Date" required />
                <MYCDate name="accidentDateTime" label="Date of Accident" required />
                <MYCInput name="location" label="Location" required />
                <MYCInput name="vehicleMakeModel" label="Vehicle Make and Model" required />
                <MYCInput name="vehicleRegNo" label="Vehicle Reg No" required />
                <MYCInput name="vehicle2RegNo" label="Other Vehicle No" />
                <MYCInput name="vehicle2MakeModel" label="Other Make and model" />
                <ChoiceView label="Vehicle Towed" update={setVTow} yes={vehicleTowed} />
                <ChoiceView label="Police on Scene" update={setPoliceScene} yes={policeScene} />
                <MYCTextArea name="description" label="Description" />
                <span>Photos</span>
                <div className='grid grid-cols-3 gap-5'>
                    {detailsRq?.data?.accidentReportPics?.map?.(M => <img src={getResourceUrl(M?.image)} className="h-72 w-72 object-contain self-center" />)}
                </div>
                <span>Admin Information</span>
                <MYCInput label="SAPS AR" name="sapsar" />

                <span>Sketch of Incident</span>
                <div className="flex gap-5 items-center flex-col">
                    <div className="rounded-full border flex flex-col items-center justify-center border-solid border-b overflow-hidden w-32 h-32 ">
                        <img src={img || (detailsRq?.data?.sketch ? getResourceUrl(detailsRq?.data?.sketch) : false) || Assets.Upload} className={`object-contain ${img || detailsRq?.data?.sketch ? "w-full h-full" : "w-10 h-10"}`} />
                    </div>
                    <Button type="primary" onClick={() => inputRef?.current?.click()}>Upload Image</Button>
                </div>
                <MYCInput name="insurance" label="Insurance Claim" />
                <Button htmlType='submit' loading={updateRq?.loading} className="w-1/3 self-end" type="primary">Save</Button>
            </div>
        </Form>
    </>
}