import { Button, Form } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { AccidentCols, AccidentData } from '../../columns/accident.cols'
import { MYCDate, MYCRemoteSearchSelect, MYCSearch } from '../../components/input_form.component'
import { MYCTable } from '../../components/table.component'
import { useGetPaginated } from '../../hooks/useRest.hook'
import { Endpoints } from '../../constants/endpoint.constant'
import { useState } from 'react'
import { useForm } from 'antd/es/form/Form'

export const AccidentScreen = ({ admin }) => {
    const [query, setQuery] = useState({});
    const [form] = useForm();
    const navigate = useNavigate();
    const accidentsRq = useGetPaginated(Endpoints.ACCIDENTS,true,query);
    return <>
        <span className='self-center text-lg'>Accident Reports</span>
        {admin && <div className="flex flex-row items-end my-10 justify-between">
            <Form form={form} onFinish={setQuery} layout="vertical">
                <div className="flex flex-row items-end gap-5">
                    <MYCRemoteSearchSelect name="clientId" labelDef={d => (d?.user?.name + `   (${d?.user?.email})`)} url={Endpoints.ALL_CLIENTS} className="flex-1" label="Search By Client" />
                    <MYCSearch name="regNo" label="Reg Number" />
                    <MYCDate showTime={false} name="dateTime" label="Search Date" />
                    <Button loading={accidentsRq?.loading} htmlType="submit" type="primary">Search</Button>
                </div>
            </Form>
            {/* <Button type="primary" onClick={navigate.bind(this,"/create-accident-report")}>Create Report</Button> */}
        </div>}
        <div className='w-full mt-10'>
            <MYCTable
                cols={AccidentCols(navigate)}
                {...accidentsRq}
            />
        </div>
    </>
}