import logo from './logo.svg';
import './App.css';
import { ClientApp } from './screens/client';
import { AdminApp } from './screens/admin';
import React, { useEffect } from 'react';
import axios from 'axios';
import { Endpoints } from './constants/endpoint.constant';
import { Constants } from './constants/general.constant';
import { LoadScriptNext } from '@react-google-maps/api';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
axios.defaults.baseURL = Endpoints.BASE_URL;
const libraries = ["places"];
export const UserContext = React.createContext({});
function App() {
  var SelectedApp;
  const [user, setUser] = React.useState({});
  const updateReadCount = async () => {
    let { data } = await axios.get(Endpoints.UNREAD_CONTACT, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("bearer_token")}`
      }
    });
    if (!isNaN(data?.data)) {
      setUser({ ...user, unreadMessages: data?.data });
    }
  }
  useEffect(async () => {
    try {
      let { data } = await axios.post(Endpoints.TOKEN_VALIDITY, {
        Token: localStorage.getItem("bearer_token")
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("bearer_token")}`
        }
      });
      if (data.success) {
        axios.defaults.headers["Authorization"] = `Bearer ${data.data.token}`;
        localStorage.setItem("bearer_token", data.data.token);
        setUser(data.data.user);
      }
    } catch (e) {
      console.debug("[TOKEN HELLO] error", e);
    }
  }, [])
  switch (process.env.REACT_APP_MODE) {
    case Constants.CLIENT:
      SelectedApp = ClientApp;
      break;
    default:
      SelectedApp = AdminApp;
  }
  return <LoadScriptNext
  libraries={libraries}
    googleMapsApiKey={Constants.GOOGLE_API_KEY}
  ><UserContext.Provider value={{ user, setUser, updateReadCount }}>
      <SelectedApp />
      <ToastContainer 
      position="top-center"
      theme="colored"
      
      />
    </UserContext.Provider>
  </LoadScriptNext>
}

export default App;
