import { Button, Form } from 'antd';
import React, { useState } from 'react';
import { Assets } from '../../assets';
import { MYCDate, MYCSearch, MYCSelect } from '../../components/input_form.component';
import { TripOrderCard } from '../../components/trip_order_card.component';
import { useGet, useGetPaginated } from '../../hooks/useRest.hook';
import { Endpoints } from '../../constants/endpoint.constant';
import { MYCPagination } from '../../components/table.component';
import { useForm } from 'antd/es/form/Form';
import { FILTER_TRIP_STATUSES, TRIP_STATUSES, TripStatus, isAdmin } from '../../constants/general.constant';
import { useNavigate } from 'react-router-dom';

const TRIPS = [
    {
        img: Assets.Avis,
        name: "John Doe",
        city: "Cape Town",
        number: "021389021",
        email: "john@mail.com",
    },
    {
        img: Assets.Avis,
        name: "John Doe",
        city: "Cape Town",
        number: "021389021",
        email: "john@mail.com",
    },
    {
        img: Assets.Avis,
        name: "John Doe",
        city: "Cape Town",
        number: "021389021",
        email: "john@mail.com",
    },
    {
        img: Assets.Avis,
        name: "John Doe",
        city: "Cape Town",
        number: "021389021",
        email: "john@mail.com",
    },
]

export const ActiveTripsScreen = ({history}) => {
    const [query, setQueryParams] = useState({
        status: history && TripStatus.Completed,
    });
    const [form] = useForm();

    const ordersRq = useGetPaginated(Endpoints.ALL_ORDERS,true,query);
    const originsRq = useGet(Endpoints.GET_ORIGINS);
    const navigate = useNavigate();
    
    const _onSearch = values => {
        console.log("[SEARCH TRIP] value",values);
        if (values.date) {
            values.date = values.date?.$d?.toJSON?.() ?? undefined;
        }
        setQueryParams(values);
    }
    return <>
        <span className='self-center text-lg'>{history?"Trip Order History" : "Client Active Orders Terminal"}</span>
        <div className="self-center w-full my-5">
            <div className="flex flex-row gap-5  justify-between">
                <Form form={form} onFinish={_onSearch} layout="vertical">
                    <div className="flex items-end flex-row gap-5">
                        <MYCSearch name="clientRefNumber" className="flex-1" label="Search By Ref Number" />
                        {isAdmin && <MYCSearch name="clientName" className="flex-1" label="Search By Client Name" />}
                        <MYCSelect initalValue={history && {label: TRIP_STATUSES[TripStatus.Completed], value: TripStatus.Completed}}  name="status" className="flex-1" label="Search by Trip Status" options={FILTER_TRIP_STATUSES?.map((M,i) => ({label:M.label, value: M.value})).filter((F,i) => (!history || F.value === 4 || F.value === 5))}/>
                        <MYCDate showTime={false} name="date"  label="Search Date" />
                        <Button htmlType="submit" className="px-5" type="primary">Search</Button>
                    </div>
                </Form>
                <Button onClick={() => navigate("/new-trip-order")} className="self-end px-10" type="primary">Create Trip Order</Button>
            </div>
        </div>
        <div className="flex flex-col gap-5">
            {ordersRq?.list?.map?.(T => <TripOrderCard driver={T?.driver} key={T?.tripOrder.id.toString()} origins={originsRq?.data} refresh={ordersRq?.refresh}  trip={T?.tripOrder} />)}
            <MYCPagination {...ordersRq}/>
        </div>


    </>
}