import { Button, Form, Modal } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ClientCols, ClientData } from '../../columns/clients.cols';
import { MYCInput, MYCSearch, MYCSelect } from '../../components/input_form.component';
import { MYCTable } from '../../components/table.component';
import { useGetPaginated, usePost } from '../../hooks/useRest.hook';
import { Endpoints } from '../../constants/endpoint.constant';
import { ClientStatus } from '../../constants/general.constant';
import { useForm } from 'antd/es/form/Form';

export const ClientsScreen = () => {
    const [kmModal, setKmModal] = useState(false);
    const navigate = useNavigate();
    const [form] = useForm();
    const [query, setQuery] = useState({});
    const clientsRq = useGetPaginated(Endpoints.ALL_CLIENTS, true, query);
    const updateRateRq = usePost(Endpoints.UPDATE_CLIENT_RATE);
    const [updateForm] = useForm();


    const _updateRate = async values => {
        values.ClientId = kmModal;
        try {
            var data = await updateRateRq.put(values);
            setKmModal(false);
            clientsRq.refresh();
        } catch (e) {

        }
    }

    return <>
        <span className='self-center text-lg'>
            All Clients
        </span>
        <div className="flex my-12 flex-row items-center justify-between">
            <Form form={form} onFinish={setQuery} className="flex-1" layout="vertical">
                <div className="flex items-end flex-row gap-5">
                    <MYCSearch name="search" className="flex-1" label="Search Client" />
                    <MYCSelect name="status" className="flex-1" label="Status" options={[
                        { label: "All", value: null },
                        ...ClientStatus.map(M => ({ label: M.label, value: M.id }))
                    ]}
                    />
                    <Button loading={clientsRq?.loading} htmlType="submit" type="primary">Search</Button>
                </div>
            </Form>
            <div className="flex-1 self-end flex flex-row justify-end">
                <Button onClick={navigate.bind(this, "/create-client")} type="primary" className="px-10">Create Client</Button>

            </div>
        </div>
        <MYCTable
            cols={ClientCols({ setKmModal,navigate })}
            {...clientsRq}
        />
        <Modal open={kmModal} onCancel={setKmModal.bind(this, false)} title="Update Price Per KM" footer={null}>
            <Form form={updateForm} onFinish={_updateRate} layout="vertical">
                <div className="flex flex-col w-full gap-5">
                    <MYCInput name="Rate" className="flex-1" type="number" label="Price Per KM" />
                    <Button loading={updateRateRq?.loading} htmlType="submit" onClick={setKmModal.bind(this, false)} type="primary" className="self-end px-10">Update</Button>
                </div>
            </Form>
        </Modal>
    </>
}