import { Button, Checkbox, Form, Modal } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ContactMessageData, ContactMessagesCols } from '../../columns/contact_msgs.cols';
import { PushNotificationHistoryCols, PushNotificationHistoryData } from '../../columns/push_notification_history.cols';
import { MYCRemoteSearchSelect, MYCSearch, MYCSelect, MYCTextArea } from '../../components/input_form.component';
import { MYCTable } from '../../components/table.component';
import { useGet, useGetPaginated, usePost } from '../../hooks/useRest.hook';
import { Endpoints } from '../../constants/endpoint.constant';
const PUSH = 0, CONTACT = 1, CANCELED = 2;
export const MessageCenterScreen = () => {
    const [step, setStep] = useState(PUSH);
    return <>
        <span className='self-center text-lg'>
            Message Center
        </span>
        <div className='self-center my-10 flex flex-row w-1/2'>
            <div onClick={setStep.bind(this, PUSH)} className={`${step === PUSH ? 'bg-primary-orange   text-white' : 'bg-white text-black border border-black border-solid '} cursor-pointer text-center flex-1 py-3`}>
                Push Notification
            </div>
            <div onClick={setStep.bind(this, CONTACT)} className={`${step === CONTACT ? 'bg-primary-orange   text-white' : 'bg-white text-black border border-black border-solid '} cursor-pointer text-center flex-1 py-3`}>
                Contact Us Messages
            </div>

        </div>
        {
            step === PUSH ? <PushNotificationView />
                : step === CONTACT ? <ContactUsMessagesView /> : <div />
        }
    </>
}

const PushNotificationView = () => {
    const [form] = Form.useForm();
    const [viewForm] = Form.useForm();
    const [query, setQuery] = useState({});
    const isAllClients = Form.useWatch('allClients', form);
    const isAllDrivers = Form.useWatch('allDrivers', form);

    const originsRq = useGet(Endpoints.GET_ORIGINS);
    const submitPushnoti = usePost(Endpoints.SUBMIT_PUSH_NOTI);
    const notisRq = useGetPaginated(Endpoints.GET_PUSH_NOTIS, true, query);
    const [rcvModal, setRcvModal] = useState(false);
    const [viewModal, setViewModal] = useState(false);


    const onSubmitNoti = async values => {
        console.log(values);
        if (isAllClients) {
            values.ClientIds = values.ClientIds || [];
        } else if (values.ClientIds?.length == 0) values.ClientIds = null;
        if (isAllDrivers) {

            values.DriverIds = values.DriverIds || [];
        } else if (values.DriverIds?.length == 0) values.DriverIds =  null;

        try {
            var data = await submitPushnoti.post(values);
            form.resetFields();
        } catch (e) {

        }
    }

    return <> <div className='flex flex-col w-2/3 self-center gap-5'>
        <Form onFinish={onSubmitNoti} form={form} layout='vertical'>
            <MYCTextArea name="Description" label="Message" required />
            <div className=' mt-5 grid grid-cols-2 gap-3'>
                {!isAllClients ? <MYCRemoteSearchSelect multiple name="ClientIds" labelDef={d => (d?.user?.name + `   (${d?.user?.email})`)} url={Endpoints.ALL_CLIENTS} disabled={isAllClients} className="flex-1" label="Search By Client" />
                    : <div></div>
                }

                <Form.Item label valuePropName="checked" name={"allClients"}>
                    <Checkbox>All Clients</Checkbox>
                </Form.Item>
                {!isAllDrivers ? <MYCRemoteSearchSelect multiple name="DriverIds" labelDef={d => d?.user?.name + " (" + d?.user?.email + ")"} url={Endpoints.ALL_DRIVERS} searchAttr="driverName" disabled={isAllDrivers} className="flex-1" label="Driver" />
                    : <div></div>}
                <Form.Item label valuePropName="checked" name={"allDrivers"}>
                    <Checkbox>All Drivers</Checkbox>
                </Form.Item>
                <MYCSelect required options={
                    originsRq?.data?.map?.(M => ({ value: M.id, label: M.name }))
                } name="OriginId" label={"Region"} />
                <Button htmlType="submit" loading={submitPushnoti?.loading} className='mt-auto' type='primary'>Send Message</Button>
            </div>
        </Form>
        <span className='my-16 self-center text-lg'>
            Push Notification History
        </span>
        <Form form={viewForm} onFinish={setQuery} layout='vertical'>
            <div className=' mt-5 grid grid-cols-2 gap-3'>
                <MYCRemoteSearchSelect name="clientId" labelDef={d => (d?.user?.name + `   (${d?.user?.email})`)} url={Endpoints.ALL_CLIENTS} className="flex-1" label="Search By Client" />

                <MYCRemoteSearchSelect name="driverId" labelDef={d => d?.user?.name + " (" + d?.user?.email + ")"} url={Endpoints.ALL_DRIVERS} searchAttr="driverName" className="flex-1" label="Driver" />
                <MYCSelect options={
                    originsRq?.data?.map?.(M => ({ value: M.id, label: M.name }))
                } name="originId" label={"Region"} />
                <Button htmlType="submit" loading={notisRq?.loading} className='mt-auto' type='primary'>Search</Button>
            </div>
        </Form>
    </div>
        <div className='my-10'>
            <MYCTable cols={PushNotificationHistoryCols({ viewTo: setRcvModal, viewDetail: setViewModal })} {...notisRq} />
        </div>
        <Modal open={rcvModal} onCancel={setRcvModal.bind(this, false)} title="Recipients" footer={null}>
            <div className="flex flex-col gap-3">
                {rcvModal?.driverId ? <span>Driver ID: {rcvModal?.driverId}</span> : <span>All Drivers</span>}
                {rcvModal?.clientId ? <span>Client ID: {rcvModal?.clientId}</span> : <span>All Clients</span>}
                <span>Region: {rcvModal?.origin?.name}</span>
            </div>
        </Modal>
        <Modal open={viewModal} footer={null} title="Notification Details" onCancel={setViewModal.bind(this, false)} >
            <div className=" py-5 grid grid-cols-2 gap-5">
                <span>Message</span>
                <span>{viewModal?.description}</span>
                <div className="flex  items-center">
                    <span>Sent To</span>
                </div>
                <div className="flex gap-5 flex-col">
                    {viewModal?.driverId ? <span>Driver ID: {viewModal?.driverId}</span> : <span>All Drivers</span>}
                    {viewModal?.clientId ? <span>Client ID: {viewModal?.clientId}</span> : <span>All Clients</span>}
                    {viewModal?.origin ? <span>Region: {viewModal?.origin?.name}</span> : <div />}
                </div>
                <span>Time</span>
                <span>{new Date(viewModal?.createdAt).toLocaleTimeString()}</span>
                <span>Date</span>
                <span>{new Date(viewModal?.createdAt).toLocaleDateString()}</span>
            </div>
        </Modal>
    </>
}

const ContactUsMessagesView = () => {
    const contactRq = useGetPaginated(Endpoints.GET_CONTACTS_US);
    const navigate = useNavigate();
    const delRq = usePost(Endpoints.DELETE_CONTACT);
    const _onDel = async id => {
        try {
            if (!window.confirm("Are you sure to delete? (This action is irreversible)")) return;
            let form = new FormData();
            form.append("id", id);
            await delRq?.post(form);
            contactRq?.refresh?.();
        } catch {

        }
    }
    return <MYCTable cols={ContactMessagesCols({ view: id => navigate("/contact-us-details/" + id), del: _onDel })}

        {...contactRq}
    />
}