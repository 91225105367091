import React from 'react';
import { useParams } from 'react-router-dom';
import { useGet, usePost } from '../../hooks/useRest.hook';
import { Endpoints } from '../../constants/endpoint.constant';
import { Button, Form } from 'antd';
import { MYCTextArea } from '../../components/input_form.component';
import { useForm } from 'antd/es/form/Form';
const HISTORY_DATA = [
    {
        time: "08:00 AM",
        date: "12/12/2023",
        message: `Good day David, yes one of our agents will call you today at 1PM.
        We look forward to discussing the expansion with you.`
    },
    {
        time: "08:00 AM",
        date: "12/12/2023",
        message: `Good day David, yes one of our agents will call you today at 1PM.
        We look forward to discussing the expansion with you.`
    },
    {
        time: "08:00 AM",
        date: "12/12/2023",
        message: `Good day David, yes one of our agents will call you today at 1PM.
        We look forward to discussing the expansion with you.`
    },
]
export const ContactUsDetailScreen = () => {
    const { id } = useParams();
    const detailRq = useGet(Endpoints.GET_CONTACT_US_DETAILS(id))
    const responseRq = usePost(Endpoints.RESPONSE_CONTACT_US(id));
    const [form] = useForm();
    if (detailRq?.loading) {
        return <span>Please wait</span>
    }

    const _onFinish = async values => {
        try {
            var data = await responseRq.put(values);
            detailRq.load();
        } catch (e) {

        }
    }

    const currentMsg = detailRq?.data?.currentMessage;
    const otherMessages = detailRq?.data?.otherMessages;
    return <>
        <span className='self-center text-lg'>
            Client Contact Us Details
        </span>

        <div className=" text-center w-2/3 self-center my-5 grid grid-cols-3 gap-5">
            <span className="font-bold text-center text-xl">Name</span>
            <span className="font-bold text-xl text-center">Phone</span>
            <span className="font-bold text-xl text-center ">Email</span>
            <span>{currentMsg?.name}</span>
            <span>{currentMsg?.number}</span>
            <span>{currentMsg?.email}</span>
        </div>
        <div className="flex flex-col w-2/3 self-center my-16 gap-5">
            <span className="text-center text-xl font-bold">Full Message</span>
            <span>{currentMsg?.message}</span>
            <span className="text-center text-xl font-bold">Response</span>
            {currentMsg?.response ? <div className="p-10 border border-solid border-black">{currentMsg?.response}</div>
                :
                <Form form={form} disabled={currentMsg?.response} onFinish={_onFinish}>
                    <MYCTextArea initialValue={currentMsg?.response} name="response" />
                    <div className="flex mt-5  flex-row justify-end">
                        <Button htmlType="submit" loading={responseRq?.loading} type="primary" className="w-1/3">Submit</Button>
                    </div>
                </Form>}
            <span className="text-center text-xl font-bold">History</span>
            {otherMessages.map?.((V, I) => <div className={`${I !== (HISTORY_DATA.length - 1) ? "border-0 border-solid py-5 border-b border-b-gray-300" : ""} gap-5 flex flex-col items-start`}>
                <span>{new Date(V.createdAt).toLocaleDateString()} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {new Date(V.createdAt).toLocaleTimeString()}</span>
                <span>{V.message}</span>
                {V.response && <> <span className="text-center text-xl font-bold">Response</span>
                    <div className="p-10 border border-solid border-black">{V?.response}</div>
                </>}
            </div>)}
        </div>
    </>
}