import { Button, Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { MYCInput } from '../../components/input_form.component';
import { Assets } from '../../assets';
import { Endpoints } from '../../constants/endpoint.constant';
import { useGet } from '../../hooks/useRest.hook';
import { useForm } from 'antd/es/form/Form';
import { Constants } from '../../constants/general.constant';
import { DirectionsService, GoogleMap, LoadScript, Marker, Polygon, Polyline } from '@react-google-maps/api';
import { Colors } from '../../constants/colors.constants';
import axios from 'axios';
import { decode } from '@googlemaps/polyline-codec';
var axiosClient = axios.create();
const defaultProps = {
    center: {
        lat: -26.197692198962454,
        lng: 28.040022154329176
    },
    zoom: 11
};


const TRACK = 0, ROUTE = 1;
const MarkerComponent = ({ src }) => <img src={src} className="w-10 h-10 object-contain" />

const DATA = [
    {
        "id": 4,
        "tripOrderId": 1,
        "tripOrder": null,
        "latitude": 33.546163,
        "longitude": 73.094735,
        "createdAt": "2023-04-05T11:28:39.692438Z"
    },
    {
        "id": 5,
        "tripOrderId": 1,
        "tripOrder": null,
        "latitude": 33.556163,
        "longitude": 73.098735,
        "createdAt": "2023-04-05T11:29:02.964572Z"
    },
    {
        "id": 6,
        "tripOrderId": 1,
        "tripOrder": null,
        "latitude": 33.656163,
        "longitude": 73.198735,
        "createdAt": "2023-04-05T11:29:19.1736Z"
    },
    {
        "id": 7,
        "tripOrderId": 1,
        "tripOrder": null,
        "latitude": 33.756163,
        "longitude": 73.298735,
        "createdAt": "2023-04-05T11:29:27.715984Z"
    }
];

export const TrackOrderScreen = () => {
    const [mode, setMode] = useState(TRACK);
    const [points, setPoints] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const tripOrderRq = useGet(Endpoints.FIND_ORDER_REG_REF(searchTerm), false);
    const trackDriverRq = useGet(Endpoints.GET_TRIP_ORDER(tripOrderRq?.data?.id), true);
    const polyLineRq = useGet(Endpoints.POLYLINE, false, {
        origin: `${tripOrderRq?.data?.pickUpLatitude},${tripOrderRq?.data?.pickUpLongitude}`,
        destination: `${tripOrderRq?.data?.dropOffLatitude},${tripOrderRq?.data?.dropOffLongitude}`,
    });
    const [polyLine, setPolyline] = useState(null);
    const [form] = useForm();
    const onFinish = values => {
        setSearchTerm(values.search);
    }
    useEffect(() => {
        if (searchTerm) {
            tripOrderRq?.load?.();
        }
    }, [searchTerm])

    const reloadRoute = () => {
        if (mode === ROUTE) {
            if (searchTerm) {
                tripOrderRq?.load?.();
            }
        }
    }
    useEffect(() => {
        if (polyLineRq?.data) {
            try {
                var decodedPoints = decode(polyLineRq?.data);
                setPolyline(decodedPoints);
            } catch {

            }
        } else {
            setPolyline(null)
        }
    }, [polyLineRq?.data]);
    useEffect(() => {
        if (tripOrderRq?.data?.pickUpLatitude != null && tripOrderRq?.data?.pickUpLongitude != null && tripOrderRq?.data?.dropOffLatitude != null && tripOrderRq?.data?.dropOffLongitude != null) {
            polyLineRq?.load?.();
        }
    }, [tripOrderRq?.data?.pickUpLatitude, tripOrderRq?.data?.pickUpLongitude, tripOrderRq?.data?.dropOffLongitude, tripOrderRq?.data?.dropOffLatitude])

    useEffect(() => {
        if (mode === TRACK && tripOrderRq?.data?.id) {
            let interval = setInterval(() => {
                trackDriverRq?.load?.();
            }, 1500);
            return () => clearInterval(interval);
        } else {
            setPoints(tripOrderRq?.data?.driverTrackings ?? []);
        }
    }, [mode, tripOrderRq?.data])


    useEffect(() => {
        if (mode === TRACK && trackDriverRq?.data?.id) {
            if (trackDriverRq?.data?.lat !== null)
                setPoints([{ latitude: trackDriverRq?.data?.lat, longitude: trackDriverRq?.data?.lng }]);
            else setPoints([]);
        }
    }, [mode, trackDriverRq?.data])
    console.log({ lat: points[Math.floor(points.length / 2)]?.latitude, lng: points[Math.floor(points.length / 2)]?.longitude })
    return <>
        <span className='self-center text-lg'>
            Track your vehicles
        </span>
        <div className='flex flex-row gap-10 w-full mt-20'>
            <div className='flex flex-col flex-1'>
                <div className='flex flex-row items-end w-full'>
                    <Form form={form} onFinish={onFinish} layout='vertical' className='flex flex-col flex-1'>
                        <MYCInput name="search" label={"Client Reference Number (eg. VTC/RA), Vehicle Reg No"} type="text" required />
                        <span className="text-primary-orange">MYC Ref Number - This can be found in your Trip Order Overview tab (please enter only the number)</span>

                        <Button htmlType="submit" type='primary' loading={tripOrderRq?.loading} className='self-end w-1/3 my-5'>Search</Button>
                    </Form>
                </div>

                {tripOrderRq?.data?.id &&
                    <div className='grid gap-5 grid-cols-3 mt-20'>
                        <span className='text-primary-orange font-bold text-xl'>
                            Trip Ref Number | Reg No
                        </span>
                        <span className='text-primary-orange font-bold text-xl'>
                            Order Route
                        </span>
                        <div></div>
                        {(tripOrderRq?.data?.clientReferenceNumber == "" && tripOrderRq?.data?.vehicleRegNumber == "" && tripOrderRq?.data?.clientReferenceNumber !== "0" && tripOrderRq?.data?.vehicleRegNumber !== "0") ? "Not entered" :
                            <span>{(tripOrderRq?.data?.clientReferenceNumber == "" && tripOrderRq?.data?.clientReferenceNumber !== "0") ? "Not entered" : tripOrderRq?.data?.clientReferenceNumber} | {(tripOrderRq?.data?.vehicleRegNumber == "" && tripOrderRq?.data?.vehicleRegNumber !== "0") ? "Not entered" : tripOrderRq?.data?.vehicleRegNumber}</span>
                        }
                        <span className="flex flex-col justify-center" >Pickup - {tripOrderRq?.data?.pickUp}</span>
                        <Button onClick={() => { setMode(ROUTE); reloadRoute() }} type={mode === ROUTE ? "primary" : undefined}>Track</Button>
                        <div></div>
                        <span className="flex flex-col justify-center">Dropoff - {tripOrderRq?.data?.dropOff}</span>
                        <Button onClick={() => setMode(TRACK)} type={mode === TRACK ? "primary" : undefined} >View Route</Button>
                    </div>
                }
            </div>
            <div className='flex-1' style={{ height: 500 }}>
                {tripOrderRq?.data?.id &&
                    <GoogleMap
                        mapContainerStyle={{ height: 500 }}

                        center={points?.length > 0 ? { lat: points[Math.floor(points.length / 2)]?.latitude, lng: points[Math.floor(points.length / 2)]?.longitude }
                            : polyLine?.length > 0 ? { lat: polyLine[0][0], lng: polyLine[0][1] } : undefined}
                        zoom={mode === TRACK ? 14 : 13.3}
                    >
                        {polyLine && Array.isArray(polyLine) && <Polyline
                            path={polyLine?.map?.(M => ({ lat: M[0], lng: M[1] }))}
                            options={{
                                strokeColor: Colors.primaryOrange
                            }}
                        />
                        }
                        {mode === TRACK ?
                            points?.length > 0 ?
                                <Marker
                                    icon={{
                                        strokeColor: Colors.primaryOrange
                                    }}
                                    position={{
                                        lat: points[0].latitude,
                                        lng: points[0].longitude
                                    }}
                                >
                                </Marker> : <></>
                            : <>

                                {points?.map(M => <Marker
                                    icon={{
                                        fillColor: Colors.primaryOrange
                                    }}
                                    position={{
                                        lat: M.latitude,
                                        lng: M.longitude
                                    }}
                                ></Marker>)}
                                {tripOrderRq?.data?.lat && <Marker
                                    icon={{
                                        strokeColor: Colors.primaryOrange
                                    }}
                                    position={{
                                        lat: tripOrderRq?.data?.lat,
                                        lng: tripOrderRq?.data?.lng
                                    }}
                                    animation="DROP"
                                ></Marker>

                                }
                            </>
                        }

                    </GoogleMap>
                }
                {/* // <GoogleMapReact
                //     bootstrapURLKeys={{ key: Constants.GOOGLE_API_KEY}}
                //     defaultCenter={defaultProps.center}
                //     defaultZoom={defaultProps.zoom}
                //     center={{ lat: points[Math.floor(points.length / 2)]?.latitude, lng: points[Math.floor(points.length / 2)]?.longitude }}

                // >
                //     {points?.map(M => <MarkerComponent src={Assets.Marker} lat={M?.latitude} lng={M?.longitude} />)}
                // </GoogleMapReact>} */}
            </div>
        </div>
    </>
}