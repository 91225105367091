import React, { useContext } from 'react';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import { Assets } from '../../assets';
import { Button } from 'antd';
import { UserContext } from '../../App';
import { getResourceUrl } from '../../constants/endpoint.constant';

export const DashboardScreen = ({ navigator }) => {
    const navigate = useNavigate();
    const { setUser, user } = useContext(UserContext);
    return <div className='flex flex-row' style={{ width: '100vw', height: '100vh' }}>
        <div className='w-56 flex flex-col  justify-start bg-black overflow-auto scrollbar-hide'>
            <img src={Assets.Logo} className="w-32 self-center my-10 h-16 object-contain" />
            <div className='flex flex-col pl-8 pr-3'>
                {navigator?.filter?.(F => !F.noSidebar).map?.((M, index) => {
                    if (M.parent) {
                        return <span className='py-3 text-primary-orange'>{M.parent}</span>
                    }
                    return <NavLink to={M?.link} className={({ isActive }) => `no-underline border-solid border-transparent text-white py-3 ${(isActive ? 'border-b  border-b-primary-orange' : '')}`} >
                        {M?.name}
                    </NavLink>
                })}
            </div>
        </div>
        <div className='flex flex-col flex-1 '>
            <div className='h-16 flex flex-row justify-between px-10 items-center'>
                <div className='flex-1 cursor-pointer' onClick={() => navigate("/messages")}>
                    <div className="relative h-12 w-12">
                        <img src={Assets.ChatButton} className="h-12 object-contain" />
                        {user?.unreadMessages? <>
                            <div className="bg-red-600 absolute top-0 right-1 overflow-hidden rounded-full flex flex-col justify-center items-center w-5 h-5">
                                <span className="text-white text-center text-xs" >{user?.unreadMessages}</span>
                            </div></>:<div/>}
                    </div>
                </div>
            <div className='flex-1 flex flex-row justify-center'>
                    <img src={Assets.LogoDark} className="w-32 self-center my-10 h-8 object-contain" />
                </div>
                <div className='flex flex-1 justify-end flex-row items-center gap-2' >
                    Welcome Back {user?.name}
                    <div className='h-12 w-12 rounded-full overflow-hidden border border-black border-solid p-1'>
                        <img src={user?.picutre ? getResourceUrl(user?.picutre) : Assets.LogoDark} className='object-contain h-12 w-12 ' />
                    </div>
                    <Button onClick={() => {
                        localStorage.clear();
                        window.location.reload();
                    }} type="primary">Log out</Button>
                </div>

            </div>
            <div className='flex-1 px-16 py-10 flex flex-col overflow-auto'>
                <Outlet />
            </div>
        </div>
    </div>
}