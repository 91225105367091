import { Document, Image, Line, Page, Text, View } from "@react-pdf/renderer"
import { Assets } from "../assets"
import { Constants, TRIP_STATUSES, TripStatus } from "../constants/general.constant"
import { Colors } from "../constants/colors.constants"
import { getResourceUrl } from "../constants/endpoint.constant"
export const TripOrderDoc = ({ tripOrder, acceptedStatus }) => {
    return <Document>
        <TripOrderCard trip={tripOrder} acceptedStatus={acceptedStatus} />

    </Document >
}
export const CancelDoc = ({ trip }) => {
    return <Document>
        <CancelPage trip={trip} />
    </Document>
}
const CancelPage = ({ trip }) => {
    return <Page size="A4">
        <View style={{ flexDirection: 'column', padding: 10, gap: 5 }}>
            <Image src={{ uri: Assets.LogoDark }} style={{ alignSelf: 'center', width: 50, height: 50, objectFit: 'contain' }} />
            <Text>Trip Order - MYC#{trip?.id}</Text>
            <Text>Cancel Reason:{trip?.cancelledTrips[0]?.reason}</Text>
            {trip?.cancelledTrips[0]?.image && <Image source={{ uri: getResourceUrl(trip?.cancelledTrips[0]?.image) }} style={{ height: 250, width: 250, objectFit: 'contain' }} />}

        </View>
    </Page>
}
const TwoRowCol = ({ label, children }) => {
    return <View style={{ flexDirection: 'col', flex: 1, alignItems: 'flex-start', }}>
        <Text style={{ fontSize: 12, marginBottom: 10 }}>{label}</Text>
        <Text style={{ fontSize: 12 }}>{children}</Text>
    </View>
}
const Divider = () => {
    return <View style={{ height: 1, width: '100%', backgroundColor: 'gray', marginTop: 20, marginBottom: 20 }} color="gray" />
}
const TripOrderCard = ({ trip, acceptedStatus }) => {
    return <Page size="A4">
        <View style={{ flexDirection: 'column', padding: 10, gap: 5 }}>
            <Image src={{ uri: Assets.LogoDark }} style={{ alignSelf: 'center', width: 50, height: 50, objectFit: 'contain' }} />

            <Text style={{ marginBottom: 10 }}>Trip: MYC#{trip?.id}</Text>
            <Divider />
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%', gap: 10 }}>
                <TwoRowCol label="Client Name" >{trip?.client?.user?.name}</TwoRowCol>
                <TwoRowCol label="Ref Number" >{trip?.clientReferenceNumber}</TwoRowCol>
                <TwoRowCol label="Date" >{new Date(trip?.startDateTime).toLocaleDateString()}</TwoRowCol>
                <TwoRowCol label="Trip Status" >{TRIP_STATUSES[trip?.status]}</TwoRowCol>
                <TwoRowCol label="Price" >R{trip?.price?.toFixed(2)}</TwoRowCol>
            </View>
            <Divider />
            <View style={{ flexDirection: 'row', width: '100%', gap: 10 }}>
                <TwoRowCol label="Vehicle Reg No" >{trip?.vehicleRegNumber}</TwoRowCol>
                <TwoRowCol label="Vehicle Type" >Polo</TwoRowCol>
                <TwoRowCol label="Pickup Location" >{trip?.pickUp}</TwoRowCol>
                <TwoRowCol label="Dropoff Location" >{trip?.dropOff}</TwoRowCol>

            </View>
            <Divider />

            <View style={{ flexDirection: 'row', width: '100%', gap: 10 }}>
                <TwoRowCol label="Start Date" >{new Date(trip?.startDateTime).toLocaleDateString()}</TwoRowCol>
                <TwoRowCol label="Start Time" >{new Date(trip?.startDateTime).toLocaleTimeString()}</TwoRowCol>
                <TwoRowCol label="Total KM" >{trip?.totalKM?.toFixed?.(2)}KM</TwoRowCol>
                <TwoRowCol label="MYC Ref No" >MYC#{trip?.id}</TwoRowCol>

            </View>
            <Divider />
            {trip?.driver && <View style={{ flexDirection: 'row', width: '100%', gap: 10 }}>
                <TwoRowCol label="Driver Name" >{trip?.driver?.user?.name}</TwoRowCol>
                <TwoRowCol label="Time of Acceptance" >{new Date(acceptedStatus?.createdAt).toLocaleTimeString()}</TwoRowCol>
                <TwoRowCol label="Date of Acceptance" >{new Date(acceptedStatus?.createdAt).toLocaleDateString()}</TwoRowCol>
                <TwoRowCol label="Status" >{"Accepted"}</TwoRowCol>
            </View>}
            <Divider />
            <View style={{ flexDirection: 'row', width: '100%', gap: 10 }}>
                <TwoRowCol label="Driver Acceptance Message" >{trip?.driverAcceprtanceMessage ? "Sent" : "Pending"}</TwoRowCol>
                <TwoRowCol label="Driver Started Trip Message" >{trip?.driverStartedTripMessage ? "Sent" : "Pending"}</TwoRowCol>
                <TwoRowCol label="Driver Started Trip Message" >{trip?.tripCompletedMessage ? "Sent" : "Pending"}</TwoRowCol>

            </View>
            <Divider />
            <Text>Trip Status: <Text style={{ color: 'red', marginBottom: 10 }}>{TRIP_STATUSES[trip?.status]}</Text></Text>
            <Text>Trip Order Live Status</Text>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: 5, marginTop: 8 }}>
                <Text style={{ fontSize: 10, color: trip?.status === TripStatus.DriverAccepted ? 'red' : undefined }}  > 1 - Order Accepted</Text>
                <Text style={{ fontSize: 10, color: false ? 'red' : undefined }} color={false ? 'red' : undefined} > 2 - Pre Inspection</Text>
                <Text style={{ fontSize: 10, color: trip?.status === TripStatus.TripStarted ? 'red' : undefined }} color={trip?.status === TripStatus.TripStarted ? 'red' : undefined} > 3 - On Route</Text>
                <Text style={{ fontSize: 10, color: trip?.status === TripStatus.PostInspection ? 'red' : undefined }} > 4 - Post Inspection</Text>
                <Text style={{ fontSize: 10, color: trip?.status === TripStatus.Completed ? 'red' : undefined }} > 5 - Completed</Text>
            </View>
        </View>
    </Page >
}

const FieldPara = ({ label, children, height, value, style }) => {
    return <View style={{ flexDirection: 'col', gap: 3, ...(style ?? {}) }}>
        <Text style={{ fontSize: 12, alignSelf: 'center' }}>{label}</Text>
        <View style={{ border: 1, height, borderStyle: 'solid', alignSelf: 'stretch', textAlign: 'center', paddingHorizontal: 5, paddingVertical: 3 }}>
            <Text style={{ fontSize: 12 }}>{value || children}</Text>
        </View>
    </View>
}

const MyText = ({ children }) => <Text style={{ fontSize: 12 }}>{children}</Text>
const ChoiceView = ({ label, yes, yesLabel = "Yes", noLabel = "No", style }) => {
    console.log('yes', yes);
    return <View style={{ flexDirection: 'col', gap: 3, alignItems: 'center', ...(style ?? {}) }} className="flex flex-col gap-3 items-center">
        <MyText >{label}</MyText>
        <View style={{ border: 1, borderStyle: 'solid', flexDirection: 'row', justifyContent: 'space-between', gap: 10, paddingHorizontal: 10, paddingVertical: 5 }}
        >
            <View style={{ flexDirection: 'row', alignItems: 'center', gap: 2 }}>
                <MyText>{yesLabel}</MyText>
                <View style={{ backgroundColor: (yes ? Colors.primaryOrange : undefined), width: 15, height: 15, ...(yes ? { border: 1, borderStyle: 'solid' } : {}) }} ></View>
            </View>
            <View style={{ flexDirection: 'row', alignItems: 'center', gap: 2 }}>
                <MyText>{noLabel}</MyText>
                <View style={{ backgroundColor: (!yes ? Colors.primaryOrange : undefined), width: 15, height: 15, ...((!!yes) ? { border: 1, borderStyle: 'solid', borderColor: 'black' } : {}) }} ></View>
            </View>
        </View>
    </View>
}
const VehicleReviewBlock = ({ label, width = '100%', windscreen, image, dent, scratch, comment }) => {
    return <View style={{ flexDirection: 'column', gap: 5, width: width, justifyContent: 'flex-start' }} className="flex flex-col gap-5">
        <Text style={{ backgroundColor: Colors.primaryOrange, paddingHorizontal: 5, paddingVertical: 10, fontSize: 12, color: 'white', textAlign: 'center' }}>
            {label}
        </Text>
        <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: 5 }}>
            {image?.map?.(M => <Image style={{ objectFit: 'contain',width:'100%',  height:300 }} source={{ uri: getResourceUrl(M?.pic) }} className="object-contain" />)}
        </View>
        {!windscreen && <>
            <ChoiceView label="Dent" yes={dent} />
            <ChoiceView label="Scratch" yes={scratch} />
        </>}
        <FieldPara height={70} label="Comments" value={comment} />
    </View>
}

const TyreReviewBlock = ({ label, image, width = "100%", tread, pressure, make }) => {
    return <View style={{ flexDirection: 'column', gap: 5, width: width, justifyContent: 'flex-start' }} className="flex flex-col gap-5">
        <Text style={{ backgroundColor: Colors.primaryOrange, paddingHorizontal: 5, paddingVertical: 10, fontSize: 12, color: 'white', textAlign: 'center' }}>
            {label}
        </Text>
        <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: 5 }}>
            {image?.map?.(M => <Image style={{ objectFit: 'contain', height: 300,  width: '100%' }} source={{ uri: getResourceUrl(M?.pic) }} className="object-contain" />)}
        </View>
        <ChoiceView label="Tread" yesLabel="Good" noLabel="Bad" yes={tread} />
        <ChoiceView label="Pressure" yes={pressure} />
        <FieldPara label="Make" value={make} />
    </View>
}


export const CompareDocument = ({ inspect, postInspect, trip, post = false }) => {
    return <Document>
        <Page size="A4">
            <View style={{ flexDirection: 'column', padding: 10, gap: 5 }}>
                <Image src={{ uri: Assets.LogoDark }} style={{ alignSelf: 'center', width: 50, height: 50, objectFit: 'contain' }} />
                <Text style={{ alignSelf: 'center' }}>Inspection For Trip Order MYC#{trip?.id}</Text>
                <View style={{ flexDirection: 'row', gap: 5, alignItems: 'flex-start' }}>
                    <Text style={{ width: "50%", textAlign: 'center' }}>Pre Inspection</Text>
                    <Text style={{ width: "50%", textAlign: 'center' }}>Post Inspection</Text>
                </View>
                <View style={{ flexDirection: 'row', gap: 5, alignItems: 'flex-start' }}>
                    <FieldPara label="Ref Number" value={inspect?.refNumber} style={{ flex: 1 }} />
                    <FieldPara label="Ref Number" value={postInspect?.refNumber} style={{ flex: 1 }} />
                </View>

                <View style={{ flexDirection: 'row', gap: 5, alignItems: 'flex-start' }}>
                    <FieldPara label="Registration Number" value={inspect?.regsitrationNumber} style={{ flex: 1 }} />
                    <FieldPara label="Registration Number" value={postInspect?.regsitrationNumber} style={{ flex: 1 }} />
                </View>

                <View style={{ flexDirection: 'row', gap: 5, alignItems: 'flex-start' }}>
                    <FieldPara label="Make" value={inspect?.make} style={{ flex: 1 }} />
                    <FieldPara label="Make" value={postInspect?.make} style={{ flex: 1 }} />
                </View>

                <View style={{ flexDirection: 'row', gap: 5, alignItems: 'flex-start' }}>
                    <FieldPara label="Model" value={inspect?.model} style={{ flex: 1 }} />
                    <FieldPara label="Model" value={postInspect?.model} style={{ flex: 1 }} />
                </View>

                <View style={{ flexDirection: 'row', gap: 5, alignItems: 'flex-start' }}>
                    <FieldPara label="Colour" value={inspect?.colour} style={{ flex: 1 }} />
                    <FieldPara label="Colour" value={postInspect?.colour} style={{ flex: 1 }} />
                </View>

                <View style={{ flexDirection: 'row', gap: 5, alignItems: 'flex-start' }}>
                    <FieldPara label="Date Out" value={new Date(inspect?.dateOut).toLocaleDateString()} style={{ flex: 1 }} />
                    <FieldPara label="Date Out" value={new Date(postInspect?.dateOut).toLocaleDateString()} style={{ flex: 1 }} />
                </View>

                <View style={{ flexDirection: 'row', gap: 5, alignItems: 'flex-start' }}>
                    <FieldPara label="KMs Out" value={inspect?.kmsOut} style={{ flex: 1 }} />
                    <FieldPara label="KMs Out" value={postInspect?.kmsOut} style={{ flex: 1 }} />
                </View>
            </View>
        </Page>
        <Page size="A4">
            <View style={{ flexDirection: 'column', padding: 10, gap: 5 }}>
                <Image src={{ uri: Assets.LogoDark }} style={{ alignSelf: 'center', width: 50, height: 50, objectFit: 'contain' }} />
                <Text style={{ alignSelf: 'center' }}>Odometer</Text>
                <View style={{ flexDirection: 'row', gap: 5, alignItems: 'flex-start' }}>
                    <Image src={{ uri: getResourceUrl(inspect?.odometer) }} style={{ width: 150, height: 150, alignSelf: 'center', objectFit: 'contain', flex: 1 }} />
                    <Image src={{ uri: getResourceUrl(postInspect?.odometer) }} style={{ width: 150, height: 150, alignSelf: 'center', objectFit: 'contain', flex: 1 }} />
                </View>
                <View style={{ flexDirection: 'row', gap: 5, alignItems: 'flex-start' }}>
                    <FieldPara label="Time Out" value={new Date(inspect?.timeOut).toLocaleTimeString()} style={{ flex: 1 }} />
                    <FieldPara label="Time Out" value={new Date(postInspect?.timeOut).toLocaleTimeString()} style={{ flex: 1 }} />
                </View>
                <View style={{ flexDirection: 'row', gap: 5, alignItems: 'flex-start' }}>
                    <FieldPara label="From (source)" value={inspect?.source} style={{ flex: 1 }} />
                    <FieldPara label="From (source)" value={postInspect?.source} style={{ flex: 1 }} />
                </View>
                <View style={{ flexDirection: 'row', gap: 5, alignItems: 'flex-start' }}>
                    <FieldPara label="To (destination)" value={inspect?.destination} style={{ flex: 1 }} />
                    <FieldPara label="To (destination)" value={postInspect?.destination} style={{ flex: 1 }} />
                </View>
                <View style={{ flexDirection: 'row', gap: 5, alignItems: 'flex-start' }}>
                    <ChoiceView label="Fuel" yes={inspect?.fuel} style={{ flex: 1 }} />
                    <ChoiceView label="Fuel" yes={postInspect?.fuel} style={{ flex: 1 }} />
                </View>
                <View style={{ flexDirection: 'row', gap: 5, alignItems: 'flex-start' }}>
                    <FieldPara label="Type" value={"Petrol"} style={{ flex: 1 }} />
                    <FieldPara label="Type" value={"Petrol"} style={{ flex: 1 }} />
                </View>
                <View style={{ flexDirection: 'row', gap: 5, alignItems: 'flex-start' }}>
                    <FieldPara label="Lic Expiry Date" value={new Date(inspect?.licExpiry).toLocaleDateString()} style={{ flex: 1 }} />
                    <FieldPara label="Lic Expiry Date" value={new Date(postInspect?.licExpiry).toLocaleDateString()} style={{ flex: 1 }} />
                </View>
                <View style={{ flexDirection: 'row', gap: 5, alignItems: 'flex-start' }}>
                    <FieldPara label="Driver" value={inspect?.driver?.user?.name} style={{ flex: 1 }} />
                    <FieldPara label="Driver" value={postInspect?.driver?.user?.name} style={{ flex: 1 }} />
                </View>
            </View>

        </Page>
        <Page size="A4">
            <View style={{ flexDirection: 'column', padding: 10, gap: 5 }}>
                <Image src={{ uri: Assets.LogoDark }} style={{ alignSelf: 'center', width: 50, height: 50, objectFit: 'contain' }} />
                <Text style={{ alignSelf: 'center' }}>Vehicle Inspection</Text>
                <View style={{ flexDirection: 'row', gap: 5, alignItems: 'flex-start' }}>
                    <VehicleReviewBlock label="Left Side" dent={inspect?.leftDent} scratch={inspect?.leftScratch} comment={inspect?.leftCommnet} image={inspect?.leftPics} width={"50%"} />
                    <VehicleReviewBlock label="Left Side" dent={postInspect?.leftDent} scratch={postInspect?.leftScratch} comment={postInspect?.leftCommnet} image={postInspect?.leftPics} width={"50%"} />
                </View>
                <View style={{ flexDirection: 'row', gap: 5, alignItems: 'flex-start' }}>
                    <VehicleReviewBlock label="Right Side" dent={inspect?.rightDent} scratch={inspect?.rightScratch} comment={inspect?.rightComment} image={inspect?.rightPics} width={"50%"} />
                    <VehicleReviewBlock label="Right Side" dent={postInspect?.rightDent} scratch={postInspect?.rightScratch} comment={postInspect?.rightComment} image={postInspect?.rightPics} width={"50%"} />
                </View>
                <View style={{ flexDirection: 'row', gap: 5, alignItems: 'flex-start' }}>
                    <VehicleReviewBlock label="Car Front" dent={inspect?.frontDent} scratch={inspect?.frontScratch} comment={inspect?.frontComment} image={inspect?.frontPics} width={"50%"} />
                    <VehicleReviewBlock label="Car Front" dent={postInspect?.frontDent} scratch={postInspect?.frontScratch} comment={postInspect?.frontComment} image={postInspect?.frontPics} width={"50%"} />
                </View>
                <View style={{ flexDirection: 'row', gap: 5, alignItems: 'flex-start' }}>
                    <VehicleReviewBlock label="Car Rear" dent={inspect?.backDent} scratch={inspect?.backScratch} comment={inspect?.backComment} image={inspect?.backPics} width={"50%"} />
                    <VehicleReviewBlock label="Car Rear" dent={postInspect?.backDent} scratch={postInspect?.backScratch} comment={postInspect?.backComment} image={postInspect?.backPics} width={"50%"} />
                </View>
                <View style={{ flexDirection: 'row', gap: 5, alignItems: 'flex-start' }}>
                    <VehicleReviewBlock windscreen label="Windscreen" dent={false} scratch={false} comment={inspect?.windScreenComment} image={inspect?.windScreenPics} width={"50%"} />
                    <VehicleReviewBlock windscreen label="Windscreen" dent={false} scratch={false} comment={postInspect?.windScreenComment} image={postInspect?.windScreenPics} width={"50%"} />
                </View>

                <Text style={{ alignSelf: 'center' }}>Tyre Inspection</Text>
                <View style={{ flexDirection: 'row', gap: 5, alignItems: 'flex-start' }}>
                    <TyreReviewBlock label="Left Front Tyre" tread={inspect?.leftFrontTireTread} pressure={inspect?.leftFrontTirePressure} image={inspect?.leftFrontTirePics} make={inspect?.leftFrontTireMake} width={"50%"} />
                    <TyreReviewBlock label="Left Front Tyre" tread={postInspect?.leftFrontTireTread} pressure={postInspect?.leftFrontTirePressure} image={postInspect?.leftFrontTirePics} make={postInspect?.leftFrontTireMake} width={"50%"} />
                </View>
                <View style={{ flexDirection: 'row', gap: 5, alignItems: 'flex-start' }}>
                    <TyreReviewBlock label="Left Back Tyre" tread={inspect?.leftBackTireTread} pressure={inspect?.leftBackTirePressure} image={inspect?.leftBackTirePics} make={inspect?.leftBackTireMake} width={"50%"} />
                    <TyreReviewBlock label="Left Back Tyre" tread={postInspect?.leftBackTireTread} pressure={postInspect?.leftBackTirePressure} image={postInspect?.leftBackTirePics} make={postInspect?.leftBackTireMake} width={"50%"} />
                </View>
                <View style={{ flexDirection: 'row', gap: 5, alignItems: 'flex-start' }}>
                    <TyreReviewBlock label="Right Back Tyre" tread={inspect?.rightFrontTireTread} pressure={inspect?.rightFrontTirePressure} image={inspect?.rightFrontTirePics} make={inspect?.rightFrontTireMake} width={"50%"} />
                    <TyreReviewBlock label="Right Back Tyre" tread={postInspect?.rightFrontTireTread} pressure={postInspect?.rightFrontTirePressure} image={postInspect?.rightFrontTirePics} make={postInspect?.rightFrontTireMake} width={"50%"} />
                </View>
                <View style={{ flexDirection: 'row', gap: 5, alignItems: 'flex-start' }}>
                    <TyreReviewBlock label="Right Front Tyre" tread={inspect?.rightBackTireTread} pressure={inspect?.rightBackTirePressure} image={inspect?.rightBackTirePics} make={inspect?.rightBackTireMake} width={"50%"} />
                    <TyreReviewBlock label="Right Front Tyre" tread={postInspect?.rightBackTireTread} pressure={postInspect?.rightBackTirePressure} image={postInspect?.rightBackTirePics} make={postInspect?.rightBackTireMake} width={"50%"} />
                </View>


                <Text style={{ alignSelf: 'center' }}>Sign Off</Text>
                <View style={{ flexDirection: 'row', gap: 5, alignItems: 'flex-start' }}>
                    <ChoiceView label="E Tag" yes={inspect?.etag} style={{ flex: 1 }} />
                    <ChoiceView label="E Tag" yes={postInspect?.etag} style={{ flex: 1 }} />
                </View>
                <View style={{ flexDirection: 'row', gap: 5, alignItems: 'flex-start' }}>
                    <ChoiceView label="Tools & Jack" yes={inspect?.toolsAndJack} style={{ flex: 1 }} />
                    <ChoiceView label="Tools & Jack" yes={postInspect?.toolsAndJack} style={{ flex: 1 }} />
                </View>
                <View style={{ flexDirection: 'row', gap: 5, alignItems: 'flex-start' }}>
                    <ChoiceView label="Spare Wheel" yes={inspect?.spareWheel} style={{ flex: 1 }} />
                    <ChoiceView label="Spare Wheel" yes={postInspect?.spareWheel} style={{ flex: 1 }} />
                </View>
                <View style={{ flexDirection: 'row', gap: 5, alignItems: 'flex-start' }}>
                    <ChoiceView label="Removal Hubcaps" yes={inspect?.removableHubcaps} style={{ flex: 1 }} />
                    <ChoiceView label="Removal Hubcaps" yes={postInspect?.removableHubcaps} style={{ flex: 1 }} />
                </View>
                <View style={{ flexDirection: 'row', gap: 5, alignItems: 'flex-start' }}>
                    <ChoiceView label="Triangle" yes={inspect?.triangle} style={{ flex: 1 }} />
                    <ChoiceView label="Triangle" yes={postInspect?.triangle} style={{ flex: 1 }} />
                </View>
                <View style={{ flexDirection: 'row', gap: 5, alignItems: 'flex-start' }}>
                    <ChoiceView label="Parcel Tray" yes={inspect?.parcelTray} style={{ flex: 1 }} />
                    <ChoiceView label="Parcel Tray" yes={postInspect?.parcelTray} style={{ flex: 1 }} />
                </View>
                <View style={{ flexDirection: 'row', gap: 5, alignItems: 'flex-start' }}>
                    <ChoiceView label="Trailer" yes={inspect?.trailer} style={{ flex: 1 }} />
                    <ChoiceView label="Trailer" yes={postInspect?.trailer} style={{ flex: 1 }} />
                </View>
                <View style={{ flexDirection: 'row', gap: 5, alignItems: 'flex-start' }}>
                    <ChoiceView label="Tow Bar" yes={inspect?.towBar} style={{ flex: 1 }} />
                    <ChoiceView label="Tow Bar" yes={postInspect?.towBar} style={{ flex: 1 }} />
                </View>
                <View style={{ flexDirection: 'row', gap: 5, alignItems: 'flex-start' }}>
                    <FieldPara label="Date" value={new Date(inspect?.createdAt).toLocaleString()} style={{ flex: 1 }} />
                    <FieldPara label="Date" value={new Date(postInspect?.createdAt).toLocaleString()} style={{ flex: 1 }} />
                </View>
                <View style={{ flexDirection: 'row', gap: 5, alignItems: 'flex-start' }}>
                    <FieldPara label="Company Name" style={{ flex: 1 }}>{inspect?.companyName}</FieldPara>
                    <FieldPara label="Company Name" style={{ flex: 1 }}>{postInspect?.companyName}</FieldPara>
                </View>
                <View style={{ flexDirection: 'row', gap: 5, alignItems: 'flex-start' }}>
                    <FieldPara label="Region" style={{ flex: 1 }}>{inspect?.region}</FieldPara>
                    <FieldPara label="Region" style={{ flex: 1 }}>{postInspect?.region}</FieldPara>
                </View>
                <View style={{ flexDirection: 'row', gap: 5, alignItems: 'flex-start' }}>
                    <FieldPara label="Client dispatcher" style={{ flex: 1 }}>{inspect?.personName}</FieldPara>
                    <FieldPara label="Client dispatcher" style={{ flex: 1 }}>{postInspect?.personName}</FieldPara>
                </View>
                <View style={{ flexDirection: 'row', gap: 5, alignItems: 'flex-start' }}>
                    <FieldPara label="Mobile number" style={{ flex: 1 }}>{inspect?.number}</FieldPara>
                    <FieldPara label="Mobile number" style={{ flex: 1 }}>{postInspect?.number}</FieldPara>
                </View>
            </View>

        </Page>
        <Page size="A4">
            <View style={{ flexDirection: 'column', padding: 10, gap: 5 }}>
                <Image src={{ uri: Assets.LogoDark }} style={{ alignSelf: 'center', width: 50, height: 50, objectFit: 'contain' }} />
                <Text>Pre Inspection</Text>
                <InspectPage4 inspect={inspect} />
                <Text>Post Inspection</Text>
                <InspectPage4 inspect={postInspect} />
            </View>
        </Page>
    </Document>
}
const InspectPage1 = ({ inspect, width }) => {
    return <View style={{ alignSelf: 'center', width, flexDirection: 'column', gap: 10 }}>
        <FieldPara label="Ref Number" value={inspect?.refNumber} />
        <FieldPara label="Registration Number" value={inspect?.regsitrationNumber} />
        <FieldPara label="Make" value={inspect?.make} />
        <FieldPara label="Model" value={inspect?.model} />
        <FieldPara label="Colour" value={inspect?.colour} />
        <FieldPara label="Date Out" value={new Date(inspect?.dateOut).toLocaleDateString()} />
        <FieldPara label="KMs Out" value={inspect?.kmsOut} />
    </View>
}

const InspectPage2 = ({ inspect, width = "70%" }) => {
    return <View style={{ alignSelf: 'center', width, flexDirection: 'column', gap: 10 }}>
        <Text style={{ alignSelf: 'center' }}>Odometer</Text>
        <Image src={{ uri: getResourceUrl(inspect?.odometer) }} style={{ width: 150, height: 150, alignSelf: 'center', objectFit: 'contain' }} />
        <FieldPara label="Time Out" value={new Date(inspect?.timeOut).toLocaleTimeString()} />
        <FieldPara label="From (source)" value={inspect?.source} />
        <FieldPara label="To (destination)" value={inspect?.destination} />
        <ChoiceView label="Fuel" yes={inspect?.fuel} />
        <FieldPara label="Type" value={"Petrol"} />
        <FieldPara label="Lic Expiry Date" value={new Date(inspect?.licExpiry).toLocaleDateString()} />
        <FieldPara label="Driver" value={inspect?.driver?.user?.name} />
    </View>
}


const InspectPage3 = ({ inspect, width = '70%' }) => {
    return <View style={{ alignSelf: 'center', width, flexDirection: 'column', gap: 10 }}>
        <Text style={{ alignSelf: 'center' }}>Vehicle Inspection</Text>
        <VehicleReviewBlock label="Left Side" dent={inspect?.leftDent} scratch={inspect?.leftScratch} comment={inspect?.leftCommnet} image={inspect?.leftPics} />
        <VehicleReviewBlock label="Right Side" dent={inspect?.rightDent} scratch={inspect?.rightScratch} comment={inspect?.rightComment} image={inspect?.rightPics} />
        <VehicleReviewBlock label="Car Front" dent={inspect?.frontDent} scratch={inspect?.frontScratch} comment={inspect?.frontComment} image={inspect?.frontPics} />
        <VehicleReviewBlock label="Car Rear" dent={inspect?.backDent} scratch={inspect?.backScratch} comment={inspect?.backComment} image={inspect?.backPics} />
        <VehicleReviewBlock windscreen label="Windscreen" dent={false} scratch={false} comment={inspect?.windScreenComment} image={inspect?.windScreenPics} />

        <Text style={{ alignSelf: 'center' }}>Tyre Inspection</Text>
        <TyreReviewBlock label="Left Front Tyre" tread={inspect?.leftFrontTireTread} pressure={inspect?.leftFrontTirePressure} image={inspect?.leftFrontTirePics} make={inspect?.leftFrontTireMake} />
        <TyreReviewBlock label="Left Back Tyre" tread={inspect?.leftBackTireTread} pressure={inspect?.leftBackTirePressure} image={inspect?.leftBackTirePics} make={inspect?.leftBackTireMake} />
        <TyreReviewBlock label="Right Back Tyre" tread={inspect?.rightFrontTireTread} pressure={inspect?.rightFrontTirePressure} image={inspect?.rightFrontTirePics} make={inspect?.rightFrontTireMake} />
        <TyreReviewBlock label="Right Front Tyre" tread={inspect?.rightBackTireTread} pressure={inspect?.rightBackTirePressure} image={inspect?.rightBackTirePics} make={inspect?.rightBackTireMake} />

        <Text style={{ alignSelf: 'center' }}>Sign Off</Text>
        <ChoiceView label="E Tag" yes={inspect?.etag} />
        <ChoiceView label="Tools & Jack" yes={inspect?.toolsAndJack} />
        <ChoiceView label="Spare Wheel" yes={inspect?.spareWheel} />
        <ChoiceView label="Removal Hubcaps" yes={inspect?.removableHubcaps} />
        <ChoiceView label="Triangle" yes={inspect?.triangle} />
        <ChoiceView label="Parcel Tray" yes={inspect?.parcelTray} />
        <ChoiceView label="Trailer" yes={inspect?.trailer} />
        <ChoiceView label="Tow Bar" yes={inspect?.towBar} />
        <FieldPara label="Date" value={new Date(inspect?.createdAt).toLocaleString()} />

        <FieldPara label="Company Name">{inspect?.companyName}</FieldPara>
        <FieldPara label="Region">{inspect?.region}</FieldPara>
        <FieldPara label="Client dispatcher">{inspect?.personName}</FieldPara>
        <FieldPara label="Mobile number">{inspect?.number}</FieldPara>
    </View>
}

const InspectPage4 = ({ inspect }) => {
    return <View style={{ alignSelf: 'center', width: '100%', flexDirection: 'column', gap: 10 }}>
        <Text>Signature</Text>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <View style={{ flexDirection: 'col', padding: 10, gap: 20 }}>
                <Text>Driver Signature</Text>
                <Image src={{ uri: getResourceUrl(inspect?.driverSignature) }} style={{ width: 50, height: 50, objectFit: 'contain' }} />
            </View>
            <View style={{ flexDirection: 'col', padding: 10, gap: 20 }}>
                <Text>Client Signature</Text>
                <Image src={{ uri: getResourceUrl(inspect?.signature) }} style={{ width: 50, height: 50, objectFit: 'contain' }} />
            </View>
        </View>
    </View>

}


const InspectionModalPage = ({ inspect, trip, post = false }) => {
    return <>
        <Page size="A4">
            <View style={{ flexDirection: 'column', padding: 10, gap: 5 }}>
                <Image src={{ uri: Assets.LogoDark }} style={{ alignSelf: 'center', width: 50, height: 50, objectFit: 'contain' }} />
                <Text>{post ? "Post-" : "Pre-"} Inspection For Trip Order MYC#{trip?.id}</Text>
                <InspectPage1 width="100%" inspect={inspect} />
            </View>
        </Page>
        <Page size="A4">
            <View style={{ flexDirection: 'column', padding: 10, gap: 5 }}>
                <Image src={{ uri: Assets.LogoDark }} style={{ alignSelf: 'center', width: 50, height: 50, objectFit: 'contain' }} />
                <InspectPage2 inspect={inspect} />
            </View>
        </Page>
        <Page size="A4">
            <InspectPage3 inspect={inspect} />
        </Page>
        <Page size="A4">
            <InspectPage4 inspect={inspect} />
        </Page>
    </>
}

export const InspectionDoc = ({ inspection, trip, post = false }) => {
    return <Document>
        <InspectionModalPage inspect={inspection} trip={trip} post={post} />
    </Document>
}

export const CompleteDoc = ({ inspection, postInspection, trip, acceptedStatus }) => {
    return <Document>
        <TripOrderCard trip={trip} acceptedStatus={acceptedStatus} />
        {trip?.status === TripStatus.Cancelled ? <CancelPage trip={trip} /> : <View />}
        {inspection?.data?.id && <InspectionModalPage inspect={inspection?.data} trip={trip} />}
        {postInspection?.data?.id && <InspectionModalPage inspect={postInspection?.data} trip={trip} post={true} />}
    </Document>
}


export const AccidentDoc = ({ accident }) => {
    return <Document>
        <Page>
            <View style={{ flexDirection: 'column', padding: 10, gap: 15 }}>
                <Image src={{ uri: Assets.LogoDark }} style={{ alignSelf: 'center', width: 50, height: 50, objectFit: 'contain' }} />
                <MyText>Accident Report for Trip MYC#{accident?.tripOrderId}</MyText>

                <FieldPara label="Name">{accident?.driverName}</FieldPara>
                <FieldPara label="Number">{accident?.driver?.user?.email}</FieldPara>
                <FieldPara label="Email">{accident?.driver?.user?.number}</FieldPara>
                <FieldPara label="Police Scene">{accident?.policeScene ? "YES" : "NO"}</FieldPara>
                <FieldPara label="SAPSAR">{accident?.sapsar}</FieldPara>
                <FieldPara label="Vehicle Towed">{accident?.vehicleTowed ? "YES" : "NO"}</FieldPara>
                <FieldPara label="Vehicle Make Model">{accident?.vehicleMakeModel}</FieldPara>
                <FieldPara label="Vehicle Reg No">{accident?.vehicleRegNo}</FieldPara>
                <FieldPara label="Location">{accident?.location}</FieldPara>
                <FieldPara label="Trip Order ID">MYC{accident?.tripOrderId}</FieldPara>
                <FieldPara label="Accident Date Time">{new Date(accident?.accidentDateTime).toLocaleString()}</FieldPara>
                <FieldPara label="Driver Surname">{accident?.driverSurname}</FieldPara>
                <FieldPara label="Full Message" >{accident?.description}</FieldPara>
                {accident?.accidentReportPics?.map?.(M => <Image src={{ uri: getResourceUrl(M?.image) }} style={{ height: 100, width: 100, objectFit: 'contain', alignSelf: 'center' }} />)}

            </View>
        </Page>
    </Document>
}