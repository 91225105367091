import React, { useContext } from 'react';
import {
    BrowserRouter,
    Routes,
    Route
}
    from 'react-router-dom'
import { AdminNavigator } from '../../navigator/admin.navigator';
import { DashboardScreen } from '../common/dashboard.screen';
import { LoginScreen } from './login.screen';
import { SignInScreen } from '../common/sign_in.screen';
import { UserContext } from '../../App';
export const AdminApp = () => {
    const { user, setUser } = useContext(UserContext);
    return <BrowserRouter>
        <Routes>
            {user.id ?
                <Route path="/" element={<DashboardScreen navigator={AdminNavigator()} />}>
                    {AdminNavigator().filter(F => F.parent === undefined).map(M => <Route path={`${M.link}`} element={M.page} />)}
                </Route> : [
                    <Route path="/sign-in" element={<SignInScreen />} />,
                    <Route path="/*" element={<LoginScreen />} />,   
                ]}
        </Routes>
    </BrowserRouter>
}