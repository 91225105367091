export const Constants = {
    CLIENT: "client",
    ADMIN: "admin",
    GOOGLE_API_KEY: "AIzaSyAoilq86XEfgpEIaC1-VhquRAZyMSY39iQ"
}

export const TripAssignStatuses = {
    REQUESTED_ALL : 0,
    ACCEPTED_BY: 1,
    REASSIGNED_TO: 2
}

export const ClientStatus = [
    {
        label: "In Active",
        id: 0
    },
    {
        label: "Active",
        id: 1
    }
]



export const TripType = {
    OneWay: 0,
    Return: 1,
}
export const TripStatus = {
    TripStarted: 7,
    PostInspection: 8,
    Cancelled: 4,
    Pending: 1,
    DriverAccepted: 3,
    Completed: 5,
    Unassigned: 0
}


export const MessageType = {
    TripOrderUpdated: 3
}

export const TRIP_STATUSES = [
    "Pending", //0 
    "Sent to", //1
    "Pending",//2
    "Accepted by",//3
    "Cancelled",//4
    "Completed",//5
    "PreInspection",//6
    "On route",//7
    "PostInspection",//8
]
export const FILTER_TRIP_STATUSES = [
    {
        label: "Pending",
        value: 1
    },
    {
        label: "On Route",
        value: 7
    },
    {
        label: "Completed",
        value: 5
    },
    {
        label: "Cancelled",
        value: 4
    },
    
]


export const isAdmin = process.env.REACT_APP_MODE === Constants.ADMIN;