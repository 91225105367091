import { ClientsScreen } from "../screens/admin/clients.screen";
import { ContactUsDetailScreen } from "../screens/admin/contact_us_detail.screen";
import { CreateClientScreen } from "../screens/admin/create_client.screen";
import { CreateDriverScreen } from "../screens/admin/create_driver.screen";
import { AdminGraphScreen } from "../screens/admin/graph.screen";
import { MessageCenterScreen } from "../screens/admin/message_center.screen";
import { DriversScreen } from "../screens/admin/drivers.screen";
import { MessagesScreen } from "../screens/client/messages.screen";
import { NewOrderScreen } from "../screens/common/new_order.screen";
import { TrackOrderScreen } from "../screens/common/track.screen";
import { AccidentScreen } from "../screens/common/accidents.screen";
import { AccidentDetailScreen } from "../screens/common/accident_detail.screen";
import { ActiveTripsScreen } from "../screens/admin/active_trip_orders.screen";
import { CreateAccidentReport } from "../screens/admin/create_accident_report.screen";

export const AdminNavigator = () => [
    {
        parent: "General"
    },
    {
        name: "Messages",
        link: "message-center",
        page: <MessageCenterScreen />
    },
    {
        name: "Contact Us Details",
        link: "contact-us-details/:id",
        page: <ContactUsDetailScreen />,
        noSidebar: true
    },
    {
        name: "Messages",
        link: '',
        page: <MessageCenterScreen />,
        noSidebar: true,
    },
    {
        name: "Messages",
        link: 'messages',
        page: <MessagesScreen />,
        noSidebar: true,
    },

    {
        parent: "Clients"
    },
    {
        name: "All Clients",
        link: "clients",
        page: <ClientsScreen />
    }, {
        name: "Create Client",
        link: "create-client",
        page: <CreateClientScreen key="create" />
    },
    {
        name: "Create Client",
        link: "create-client/:id",
        noSidebar: true,
        page: <CreateClientScreen key="update" />
    },
    {
        parent: "Purchase Orders"
    },
    {
        name: "New Trip Order",
        link: "new-trip-order",
        page: <NewOrderScreen />
    },
    {
        name: "Active Trip Orders",
        link: "active-trip-orders",
        page: <ActiveTripsScreen key="active" />
    },
    {
        name: "Trip Order History",
        link: "trip-order-history",
        page: <ActiveTripsScreen key="history" history />
    },
    {
        parent: "Drivers"
    },
    {
        name: "Driver List",
        link: "drivers",
        page: <DriversScreen />
    },
    {
        name: "Create Driver",
        link: "create-driver",
        page: <CreateDriverScreen key="create" />
    },

    {
        name: "Create Driver",
        link: "create-driver/:id",
        page: <CreateDriverScreen key="update" />,
        noSidebar: true

    },
    {
        parent: "Vehicles"
    },
    {
        name: "Navigation",
        link: "track",
        page: <TrackOrderScreen />
    },
    {
        name: "Accident Details",
        link: "accident-details/:id",
        page: <CreateAccidentReport />,
        noSidebar: true
    },
    {
        name: "Create Accident",
        link: "create-accident-report",
        page: <CreateAccidentReport />,
        noSidebar: true
    },
    {
        name: "Accident Reports",
        link: "accident-reports",
        page: <AccidentScreen admin />
    },
    {
        name: "Analytics",
        link: "analytics",
        page: <AdminGraphScreen />
    },
    {
        noSidebar: true,
        name: "Messages",
        link: "sign-in",
        page: <MessageCenterScreen />
    },

]