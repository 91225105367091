import { Button, Form } from "antd"
import { MYCInput } from "./input_form.component"
import { useForm } from "antd/es/form/Form"
import { TripType } from "../constants/general.constant";

export const TripForm = ({ onSubmit, disabled, onForm, trip, index }) => {

    const [form] = useForm();

    onForm?.({ form, trip });

    return <div className='border gap-4 border-black border-solid px-8 py-4 flex flex-col items-start'>
        <span className='text-2xl'>
            Trip {index + 1}
        </span>
        <span className={`text-primary-orange text-xl ${trip?.type === TripType.OneWay? "opacity-0":""}`}>
           Shuttle / Pilot
        </span>

        <span className='text-sm'>
            Unique ID - MYC{trip?.id}
        </span>

        <Form form={form} disabled={disabled} className='w-full flex flex-col gap-5' layout='vertical'>
            <MYCInput name="ClientRefNo" label="Client Reference Number (eg. VTC/RA)" type='text' />

            <MYCInput name="VehicleRegNo" label="Vehicle Reg Number" type='text' />
            <MYCInput disabled value={trip?.pickUp} label="Pickup Location" type='text' required />
            <MYCInput disabled value={trip?.dropOff} label="Dropoff Location" type='text' required />

        </Form>

        <span>Start Date: {new Date(trip?.startDateTime).toLocaleDateString()}</span>
        <span className={`${trip?.type === TripType.Return? "opacity-0":""}`}>Start Time: {new Date(trip?.startDateTime).toLocaleTimeString()}</span>
        <div className='w-full flex flex-row justify-between'>
            <span>Total KM</span>
            <span>{trip?.totalKM?.toFixed?.(2)}KM</span>
        </div>
        <div className='w-full flex flex-row justify-between'>
            <span>Total Price</span>
            <span>R{trip?.price?.toFixed(2)}</span>
        </div>

    </div>
}