import { Button } from 'antd';
import React, { useEffect, useRef } from 'react';
import { Assets } from '../../assets';
import { useGet } from '../../hooks/useRest.hook';
import { Endpoints, getResourceUrl } from '../../constants/endpoint.constant';
import { useParams } from 'react-router-dom';
import { PDFDownloadLink, usePDF } from '@react-pdf/renderer';
import { AccidentDoc } from '../../documents/triporder.doc';

export const AccidentDetailScreen = () => {
    const { id } = useParams();
    const detailsRq = useGet(Endpoints.ACCIDENT_REPORT(id));
    const [instance, update] = usePDF({ document: AccidentDoc({ accident: detailsRq?.data }) })
    const aRef = useRef();
    console.log(aRef);
    useEffect(() => {
        if (detailsRq?.data?.id) {
            update(AccidentDoc({ accident: detailsRq?.data }))
        }
    }, [detailsRq?.data])

    return <>
        <span className='self-center text-lg'>Accident Detail</span>
        <div className='grid grid-cols-3 gap-5 mt-10'>
            <span className="text-center">Name</span>
            <span className="text-center">Number</span>
            <span className="text-center">Email</span>
            <span className="text-center font-bold">{detailsRq?.data?.driverName}</span>
            <span className="text-center font-bold ">{detailsRq?.data?.driver?.user?.number}</span>
            <span className="text-center font-bold">{detailsRq?.data?.driver?.user?.email}</span>
            <span className="text-center">Police Scene</span>
            <div />
            <div />
            <span className="text-center font-bold">{detailsRq?.data?.policeScene ? "YES" : "NO"}</span>
            <div />
            <div />
            <span className="text-center ">Vehicle Towed</span>
            <span className="text-center">Vehicle Make and Model</span>
            <span className="text-center">Vehicle Reg No</span>
            <span className="text-center font-bold">{detailsRq?.data?.vehicleTowed ? "YES" : "NO"}</span>
            <span className="text-center font-bold">{detailsRq?.data?.vehicleMakeModel}</span>
            <span className="text-center font-bold">{detailsRq?.data?.vehicleRegNo}</span>
            <span className="text-center ">Location</span>
            <span className="text-center">Trip Order Ref</span>
            <span className="text-center">Driver Surname</span>
            <span className="text-center font-bold">{detailsRq?.data?.location}</span>
            <span className="text-center font-bold">MYC{detailsRq?.data?.tripOrderId}</span>
            <span className="text-center font-bold">{detailsRq?.data?.driverSurname}</span>

        </div>
        <div className='flex flex-col gap-5 mt-20 items-center'>
            <span>Full Message</span>
            <span>{detailsRq?.data?.description}</span>
            <span>Image</span>
            <div className='grid grid-cols-3 gap-5'>
                {detailsRq?.data?.accidentReportPics?.map?.(M => <img src={getResourceUrl(M?.image)} className="h-72 w-72 object-contain self-center" />)}
            </div>
            <PDFDownloadLink fileName={`MYC-${detailsRq?.data?.tripOrderId}-` + "accident-report.pdf"} document={<AccidentDoc accident={detailsRq?.data} />}>
                {({ loading, url }) => {
                    return <div className="flex flex-row gap-2">
                        {loading ? <img src={Assets.Loading} className="w-6 h-6" /> : <Button loading={instance?.loading} type='primary' className='px-10'>Download</Button>

                        }</div>
                }}
            </PDFDownloadLink>

        </div >
    </>
}