import React, { useContext, useEffect } from 'react';
import { useGetPaginated } from '../../hooks/useRest.hook';
import { Endpoints, getResourceUrl } from '../../constants/endpoint.constant';
import { Assets } from '../../assets';
import { MessageType } from '../../constants/general.constant';
import { UserContext } from '../../App';
import { MYCPagination } from '../../components/table.component';

const Message = ({ image, time, message, extraMessage }) => {
    return <div className='flex flex-col mt-5 gap-4 border border-solid px-7 py-4'>
        <div className='flex flex-row gap-20 items-center'>
            <img src={image} className="w-24 h-24 object-contain" />
            <span>{time}<br />{message?.split?.('\n').map(M => <><br />{M}</>)}</span>
        </div>
        {extraMessage && <span className=' self-center my-3'>{extraMessage}</span>}
    </div>
}

export const MessagesScreen = () => {
    const msgRq = useGetPaginated(Endpoints.GET_MESSAGES);
    const { updateReadCount } = useContext(UserContext);
    useEffect(() => {
        if (msgRq?.pageNo === 1)
            updateReadCount?.();
    }, [msgRq?.loading])
    return <div className="w-2/3 self-center flex flex-col gap-5">
        <span className='self-center text-lg'>Messages</span>
        {msgRq?.list?.map?.(M => <Message image={M?.type === MessageType.TripOrderUpdated ? getResourceUrl(M?.tripOrder?.driver?.user?.picutre, true):Assets.LogoDark}
            message={M?.type === MessageType.TripOrderUpdated ? `Driver ${M?.tripOrder?.driver?.user?.name} accepted
            Client reference number (eg.VTC / RA):${M?.tripOrder?.clientReferenceNumber ?? ""}
            Contact Number: ${M?.tripOrder?.driver?.user?.number}` : M?.description}
            extraMessage={M?.type === MessageType.TripOrderUpdated ? M?.description : undefined}
            time={new Date(M?.createdAt).toLocaleString()}

        />)}
        <MYCPagination {...msgRq} />
    </div>
}