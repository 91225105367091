import { Button } from 'antd';
import React from 'react';
import { Assets } from '../assets'
export const ContactMessagesCols = ({ view, del }) => [
    {
        title: "Message",
        wrap: true,
        dataIndex: "message",
        width: "30%",
        key: "message"
    },
    {
        title: "From",
        dataIndex: "email",
        render: (_, record) => `${record?.name} (${record.email})`,
        width: "10%",
        key: "from",
        wrap: true,
    },
    {
        title: "Time",
        wrap: true,
        dataIndex: "createdAt",
        render: text => new Date(text).toLocaleTimeString(),
        width: "10%",
        key: "time"
    },
    {
        title: "Date",
        wrap: true,
        dataIndex: "createdAt",
        render: text => new Date(text).toLocaleDateString(),
        width: "10%",
        key: "date"
    },
    {
        title: "View",
        dataIndex: "id",
        render: (text) => <Button type="primary" onClick={view?.bind(this, text)}>View</Button>,
        width: "5%",
        key: "to"
    },
    {
        title: "Action",
        dataIndex: "id",
        render: (text) => <div onClick={del?.bind(this,text)} className="cursor-pointer flex flex-row gap-3 justify-center items-center">
            <img src={Assets.BinSquare} />
        </div>,

        width: "5%",
        key: "action"
    },
]

export const ContactMessageData = [
    {
        message: "A driver just accepted your trip order!",
        from: "David Blain",
        date: "04/02/2023",
        time: "10:00am"
    },
    {
        message: "A driver just accepted your trip order!",
        from: "David Blain",
        date: "04/02/2023",
        time: "10:00am"
    },
    {
        message: "A driver just accepted your trip order!",
        from: "David Blain",
        date: "04/02/2023",
        time: "10:00am"
    },
]