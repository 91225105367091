import { Button, Form } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Assets } from '../../assets';
import { isAdmin } from '../../constants/general.constant';

export const LoginScreen = () => {
    const navigate = useNavigate();
    return <div className='flex flex-col gap-5 justify-center items-center' style={{ height: '100vh', width: '100vw', backgroundImage: `url(${Assets.LoginBG})`, backgroundSize: 'cover' }}>
        <img src={Assets.Logo} className="object-contain w-52 h-32" />
        <div className='text-white bg-primary-orange px-12 py-3'>
            Welcome To Your <span className='text-black'>{isAdmin && "Admin"} Portal</span>
        </div>
        <span className='text-white'>
            Safe, trust worthy and money saving driver services.
        </span>
        <div className='flex flex-row gap-5'>
            <Button onClick={navigate?.bind(this, '/sign-in')} className='w-32' type='primary'>Login</Button>
            
        </div>
    </div>
}